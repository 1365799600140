import React from 'react';
import styled from 'styled-components';
import UnstyledTypography from '@material-ui/core/Typography';
import { withAuthentication } from 'react-aad-msal';
import UnstyledProfileImage from '../default/ProfileImage';
import authProvider from '../../auth/authProvider';
import { dateToString } from '../../utils/date-format';
import { useUsers } from '../../pages/cwt/hooks/useUsers';
import SiteButtonGroup from './SiteButtonGroup';
import { useRouteMatch } from 'react-router-dom';
const ProfileImage = styled(UnstyledProfileImage)``;
const Typography = styled(UnstyledTypography)``;

const Container = styled.div`
  padding-right: 18px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5px;

  ${ProfileImage} {
    margin-right: 7px;
    margin-left: 32px;
  }

  ${Typography} {
    font: ${({ theme }) => theme.fonts.medium12};
    color: ${({ theme }) => theme.colours.greys.darkGrey3};
    line-height: 20px;
    text-transform: uppercase;
  }

  .header-name {
    margin-right: 32px;
  }

  .header-date {
    margin-right: 50px;
  }
`;

interface Props {
  className?: string;
  hideIcon?: boolean;
  onIconClick(): void;
}

const Header = ({ className }: Props) => {
  const { userSite } = useUsers();
  const match = useRouteMatch({
    path: '/cwt/wr/:id/screening',
  });

  return (
    <Container className={className}>
      {userSite !== undefined && !match && <SiteButtonGroup />}
      <ProfileImage />
      <Typography className="header-name">{authProvider.getAccount().name}</Typography>
      <Typography className="header-date">{dateToString(new Date())}</Typography>
    </Container>
  );
};
export default withAuthentication(Header, { provider: authProvider });
