import React from 'react';
import * as R from 'ramda';
import useScorecard from '../../hooks/scorecard';
import useForecast from '../../hooks/forecast';
import { AllColourValuesProvider } from '../../hooks/all-colour-values';
import ForecastChart from '../ForecastChart';
import useActions from '../../hooks/actions';
import useElementActuals, { ActualsProvider } from '../../hooks/useElementActuals';
import { Scorecard } from '../types/scorecard';
import { Report } from '../../hooks/report';

interface Props {
  actions: any[];
  scorecard: Scorecard;
}

const InnerContainer = ({ actions, scorecard }: Props) => {
  const historicData = useElementActuals();

  const forecastEvents = useForecast(actions, scorecard);
  const forecastData = R.map(
    ({ date, scorecard: forecastScorecard, actions: forecastActions }) => ({
      date,
      score: forecastScorecard.totals.score,
      actions: forecastActions,
    }),
    forecastEvents
  );

  return (
    <ForecastChart
      historicData={historicData}
      forecastData={forecastData}
    />
  );
};

interface ContainerProps {
  report: Report;
}

const ForecastContainer = ({ report }: ContainerProps) => {
  const { scorecard } = useScorecard(report.elementId, parseInt(report.year, 10), parseInt(report.quarter, 10));
  const { actions } = useActions(report.elementId, 'ongoing');

  return (
    <AllColourValuesProvider>
      {R.isEmpty(scorecard) ? null : (
        <ActualsProvider
          scorecard={scorecard}
          elementId={report.elementId}
        >
          <InnerContainer
            actions={actions}
            scorecard={scorecard}
          />
        </ActualsProvider>
      )}
    </AllColourValuesProvider>
  );
};

export default ForecastContainer;
