import * as actionTypes from '../../../state/action-types';

const initialState = {
  userAssignments: {},
  elements: [],
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.ADMIN_LOAD_USER_ASSIGNMENTS_REQUEST: {
      const { userId } = action.payload;
      return {
        ...state,
        userAssignments: {
          ...state.userAssignments,
          [userId]: {
            records: [],
            isLoading: true,
          },
        },
      };
    }
    case actionTypes.ADMIN_LOAD_USER_ASSIGNMENTS_SUCCESS: {
      const { userId, userAssignments } = action.payload;
      return {
        ...state,
        userAssignments: {
          ...state.userAssignments,
          [userId]: {
            records: userAssignments,
            isLoading: false,
          },
        },
      };
    }
    case actionTypes.ADMIN_LOAD_USER_ASSIGNMENTS_FAILURE: {
      const { error, options } = action.payload;
      const { userId } = options;
      return {
        ...state,
        userAssignments: {
          ...state.userAssignments,
          [userId]: {
            isLoading: false,
            error,
          },
        },
      };
    }
    case actionTypes.ADMIN_LOAD_ELEMENTS_SUCCESS: {
      const { elements } = action.payload;
      return {
        ...state,
        elements,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
