import { Grid, Typography } from '@material-ui/core';
import { isBefore } from 'date-fns';
import { startCase } from 'lodash';
import ActionButton from '../../../components/ActionButton';
import { ControlAutocomplete } from '../../../form/ControlAutocomplete';
import { ControlBoolean } from '../../../form/ControlBoolean';
import { ControlCheckbox } from '../../../form/ControlCheckbox';
import { ControlDateTime } from '../../../form/ControlDateTime';
import { ControlTextField } from '../../../form/ControlTextField';
import { Outage } from '../../../types/outages';
import { BA_UNITS, BB_UNITS } from '../../../utils/units';
import { StyledTypography } from '../../business-equip-risks/components/form/StyledTypography';
import { UppercaseTitle } from '../../business-equip-risks/components/form/UppercaseTitle';
import { useOutageForm } from '../hooks/useOutageForm';

interface OutageFormProps {
  handleClose: () => void;
  outage?: Outage;
}

const OutageForm: React.FC<OutageFormProps> = ({ handleClose, outage }) => {
  const {
    control,
    errors,
    handleSubmit,
    outageCodeOptions,
    facilityOptions,
    unitOptions,
    resolutionOptions,
    categoryOptions,
    dispositionOptions,
    applicableUnitLabels,
    dataSource,
    durationHours,
    durationDays,
    watch,
  } = useOutageForm(handleClose, outage);

  const disableMaximoFields = !!outage && dataSource === 'maximo';

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <UppercaseTitle>Outage information</UppercaseTitle>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <ControlAutocomplete
            control={control}
            name="outageCode"
            options={outageCodeOptions}
            error={!!errors.outageCode}
            label="Outage code"
            required
            placeholder="Select outage code"
            disabled={!!outage}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <StyledTypography>Data Source:</StyledTypography>
          <Typography style={{ fontWeight: 500 }}>{startCase(dataSource)}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <UppercaseTitle>Maximo information</UppercaseTitle>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <ControlAutocomplete
            control={control}
            name="facility"
            options={facilityOptions}
            error={!!errors.facility}
            label="Facility"
            required
            placeholder="Select Facility"
            disabled={disableMaximoFields}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <ControlAutocomplete
            control={control}
            name="unit"
            options={unitOptions}
            error={!!errors.unit}
            label="Unit"
            required
            placeholder="Select Unit"
            disabled={disableMaximoFields}
          />
        </Grid>
        <Grid
          item
          xs={12}
          container
        >
          <Grid
            item
            xs={6}
          >
            <StyledTypography>Duration (Days) </StyledTypography>
            <Typography style={{ fontWeight: 500 }}>{durationDays?.toFixed(2) ?? 'N/A'}</Typography>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <StyledTypography>Duration (Hours) </StyledTypography>
            <Typography style={{ fontWeight: 500 }}>{durationHours?.toFixed(2) ?? 'N/A'}</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <ControlDateTime
            control={control}
            name="startDate"
            required
            label="Start Date"
            disableFuture
            error={!!errors.startDate}
            helperText={errors.startDate?.message}
            validate={(value, formValues) =>
              !formValues.endDate || !value || isBefore(value, formValues.endDate) || 'Must be before End Date'
            }
            deps={['endDate']}
            disabled={disableMaximoFields}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <ControlDateTime
            control={control}
            name="endDate"
            required
            label="End Date"
            disableFuture
            error={!!errors.endDate}
            helperText={errors.endDate?.message}
            validate={(value, formValues) =>
              !formValues.startDate || !value || !isBefore(value, formValues.startDate) || 'Must be after Start Date'
            }
            deps={['startDate']}
            disabled={disableMaximoFields}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <StyledTypography>Note: times are displayed in local timezone</StyledTypography>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <UppercaseTitle>Other information</UppercaseTitle>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <ControlTextField
            control={control}
            name="relatedSCR"
            error={!!errors.relatedSCR}
            label="Related SCR"
            required
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <ControlAutocomplete
            control={control}
            name="category"
            options={categoryOptions}
            error={!!errors.category}
            label="Category"
            required
            placeholder="Category"
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <ControlCheckbox
            control={control}
            name="sbgInclusion"
            label="SBG"
          />
        </Grid>
        {watch('sbgInclusion') && (
          <Grid
            item
            xs={12}
          >
            <ControlTextField
              control={control}
              name="sbgInclusionDays"
              label="SBG Days"
              required
              size="small"
              type="number"
              error={!!errors.sbgInclusionDays}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
        >
          <ControlAutocomplete
            control={control}
            name="resolution"
            options={resolutionOptions}
            error={!!errors.resolution}
            label="Resolution"
            required
            placeholder="Resolution"
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <ControlAutocomplete
            control={control}
            name="disposition"
            options={dispositionOptions}
            error={!!errors.disposition}
            label="Disposition"
            required
            placeholder="Disposition"
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <ControlTextField
            control={control}
            name="system"
            label="System"
            maxLength={127}
            required
            size="small"
            error={!!errors.system}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <ControlTextField
            control={control}
            name="component"
            label="Component"
            maxLength={127}
            required
            size="small"
            error={!!errors.component}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <ControlCheckbox
            control={control}
            name="repeatOutage"
            label="Repeat Outage"
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <ControlTextField
            control={control}
            name="eventDescription"
            label="Event Description"
            maxLength={127}
            required
            size="small"
            error={!!errors.eventDescription}
            placeholder="ex. U1 4.16kV Transformer 2 Differential Trip During SST 5.28"
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <ControlTextField
            control={control}
            name="comments"
            label="Comments"
            multiline={true}
            maxLength={1000}
            minRows={5}
            required
            error={!!errors.comments}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <Typography style={{ fontWeight: 500 }}>Applicable Units</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          container
          spacing={1}
        >
          {BA_UNITS.map(unit => (
            <Grid
              key={unit}
              item
              xs={2}
            >
              <ControlBoolean
                control={control}
                name={`applicableUnits.${unit}`}
                label={applicableUnitLabels[unit]}
                error={!!errors.applicableUnits?.[unit]}
              />
            </Grid>
          ))}
        </Grid>
        <Grid
          item
          xs={12}
          container
          spacing={1}
        >
          {BB_UNITS.map(unit => (
            <Grid
              key={unit}
              item
              xs={2}
            >
              <ControlBoolean
                control={control}
                name={`applicableUnits.${unit}`}
                label={applicableUnitLabels[unit]}
                error={!!errors.applicableUnits?.[unit]}
              />
            </Grid>
          ))}
        </Grid>
        <Grid
          item
          xs={12}
        >
          <ControlCheckbox
            control={control}
            name="verified"
            label="Verified"
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <ActionButton
            color="primary"
            type="submit"
          >
            Confirm
          </ActionButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default OutageForm;
