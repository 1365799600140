import React, { useContext } from 'react';
import * as R from 'ramda';
import useReportAttachments from '../../../../../hooks/report-attachments';

interface AttachmentsContextValue {
  // TODO: Define this type
  attachments: any[];
}

const AttachmentsContext = React.createContext<AttachmentsContextValue>({ attachments: [] });

const useAttachments = () => {
  const context = useContext(AttachmentsContext);
  if (R.isNil(context)) {
    throw new Error('useAttachments must be used inside an AttachmentsProvider');
  }
  return context;
};

export const AttachmentsProvider: React.FC<React.PropsWithChildren<{ elementId: string }>> = ({
  children,
  elementId,
}) => {
  const { attachments } = useReportAttachments(elementId);

  const value = {
    attachments,
  };

  return <AttachmentsContext.Provider value={value}>{children}</AttachmentsContext.Provider>;
};

export default useAttachments;
