import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { Scorecard as IScorecard } from 'echo-scorecard';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import useScorecard from '../../hooks/scorecard';
import { dateTimeToString } from '../../utils/date-format';
import UnstyledTotalScore from './TotalScore';
import UnstyledOutlineButton from '../OutlineButton';
import ManualValueDialog from './ManualValueDialog';
import TotalValueOverrideDialog from './TotalValueOverrideDialog';
import UnstyledScorecardTable from './ScorecardTable';
import useOverrideValue from '../../hooks/override-value';
import useEditManualIndicatorValue from '../../hooks/edit-manual-indicator-value';
import useEditManualTotalValue from '../../hooks/edit-manual-total-value';
import IndicatorDetailsDialog from './IndicatorDetailsDialog';
import useEditManualUnitTotalValue from '../../hooks/edit-manual-unit-total-value';
import { Report } from '../../hooks/report';
import ManualProgramIndicatorValueDialog from './ManualProgramIndicatorValueDialog';

const TotalScore = styled(UnstyledTotalScore)``;
const OutlineButton = styled(UnstyledOutlineButton)``;
const ScorecardTable = styled(UnstyledScorecardTable)``;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;

  ${OutlineButton} {
    margin-left: 20px;
  }
`;

const LoadingOverlay = styled(CircularProgress)``;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 8px;

  ${TotalScore} {
    align-self: flex-end;
    width: 200px;
  }

  ${ScorecardTable} {
    margin-bottom: 30px;
  }

  ${ButtonWrapper} {
    margin-bottom: 45px;
    ${OutlineButton} {
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }

  ${LoadingOverlay} {
    position: absolute;
    top: 8px;
    left: 8px;
  }
`;

const scoreValidator = {
  value: {
    validate: {
      numericality: {
        greaterThanOrEqualTo: 0,
        notGreaterThanOrEqualTo: 'Total Override Value must be greater than or equal to 0',
        lessThanOrEqualTo: 100,
        notLessThanOrEqualTo: 'Total Override Value must be less than or equal to 100',
      },
      presence: {
        allowEmpty: true,
        message: 'Enter a number',
      },
    },
  },
};

interface Props {
  report?: Report;
  className?: string;
  elementId: number;
  elementType?: string;
  facilityId: string;
  year: number;
  quarter: number;
  override?: boolean;
  isLive?: boolean;
}

const Scorecard = ({
  report = {} as Report,
  className,
  elementId,
  elementType,
  facilityId,
  year,
  quarter,
  override = false,
  isLive = false,
}: Props) => {
  const { scorecard, isLoading, lastUpdatedAt } = useScorecard(elementId, year, quarter);
  const overrideTotal: any = !R.isEmpty(report)
    ? {
        score: report?.overriddenScore ? report.overriddenScore : R.path(['totals', 'score'], scorecard),
        colour: report?.overriddenColour ? report.overriddenColour : R.path(['totals', 'colour'], scorecard),
        comment: report.overrideComment,
      }
    : null;
  const { overrideValue, editOverrideValue, cancelEditOverrideValue, saveOverrideValue, clearOverrideValue } =
    useOverrideValue(elementId, year, quarter, scorecard);
  const { manualIndicatorValue, editManualIndicatorValue, cancelEditManualIndicatorValue, saveManualIndicatorValue } =
    useEditManualIndicatorValue(elementId, year, quarter, scorecard);
  const {
    manualTotalValue,
    editManualTotalValue,
    cancelEditManualTotalValue,
    saveManualTotalValue,
    clearManualTotalValue,
  } = useEditManualTotalValue({ reportId: report.id, scorecard, overrideTotal });
  const {
    manualUnitTotalValue,
    editManualUnitTotalValue,
    cancelEditManualUnitTotalValue,
    saveManualUnitTotalValue,
    clearManualUnitTotalValue,
  } = useEditManualUnitTotalValue({ elementId, year, quarter, scorecard });

  return (
    <Container className={className}>
      {!R.isEmpty(scorecard) && (
        <>
          <TotalScore
            report={report}
            scorecard={scorecard as IScorecard}
            onClick={override ? editManualTotalValue : null}
          />
          <ScorecardTable
            scorecard={scorecard as IScorecard}
            editable
            onCalculatedScoreClicked={editOverrideValue}
            onManualScoreClicked={editManualIndicatorValue}
            onUnitTotalScoreClicked={override ? editManualUnitTotalValue : null}
          />
        </>
      )}
      {lastUpdatedAt && <Typography>Last Updated: {dateTimeToString(lastUpdatedAt)}</Typography>}
      {isLoading && <LoadingOverlay size={20} />}
      {overrideValue && (
        <IndicatorDetailsDialog
          overrideValue={overrideValue}
          facilityId={facilityId}
          elementId={elementId}
          elementType={elementType}
          year={year}
          quarter={quarter}
          onClear={clearOverrideValue}
          onCancel={cancelEditOverrideValue}
          onSave={saveOverrideValue}
          isLive={isLive}
        />
      )}
      {manualIndicatorValue && (report.elementType !== 'program' || elementType !== 'program') && (
        <ManualValueDialog
          manualValue={manualIndicatorValue}
          onCancel={cancelEditManualIndicatorValue}
          onSave={saveManualIndicatorValue}
        />
      )}
      {manualIndicatorValue && (report.elementType === 'program' || elementType === 'program') && (
        <ManualProgramIndicatorValueDialog
          manualValue={manualIndicatorValue}
          onCancel={cancelEditManualIndicatorValue}
          onSave={saveManualIndicatorValue}
          elementId={elementId}
        />
      )}
      {manualUnitTotalValue && override && (
        <ManualValueDialog
          title="Total Unit Value Override"
          validation={scoreValidator}
          manualValue={manualUnitTotalValue as any}
          onCancel={cancelEditManualUnitTotalValue}
          onSave={saveManualUnitTotalValue}
          onClear={clearManualUnitTotalValue}
        />
      )}
      {!R.isNil(manualTotalValue) && override && (
        <TotalValueOverrideDialog
          overrideValue={manualTotalValue}
          onCancel={cancelEditManualTotalValue}
          onSave={saveManualTotalValue}
          onClear={clearManualTotalValue}
        />
      )}
    </Container>
  );
};

export default Scorecard;
