// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { StylesProvider } from '@material-ui/core/styles';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch, Redirect } from 'react-router-dom';
import { History } from 'history';
import isFeatureEnabled from './utils/feature-flags';

import LoginPage from './pages/login/LoginPage';
import theme from './theme';
import DefaultLayout from './layouts/default/DefaultLayout';
import CWTLayout from './layouts/cwt/CWTLayout';

import 'material-icons/iconfont/material-icons.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/300.css';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Roboto, 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    font-size: 100%;
  }
  * {
    box-sizing: border-box;
  }
  ::-webkit-scrollbar {
    width: 5px;
    background-color: inherit;
  }

  ::-webkit-scrollbar-thumb {
    background-color: lightGrey;
    border-radius: 10px;
  }
`;

const LoggedInPage = () => (
  <>
    <h1>ECHO</h1>
    <p>You have been authenticated</p>
  </>
);

interface Props {
  history: History;
}

const App = ({ history }: Props) => (
  <StylesProvider injectFirst>
    <GlobalStyle />
    <ThemeProvider theme={theme}>
      <ConnectedRouter history={history}>
        <Switch>
          <Redirect
            exact
            from="/"
            to="/app/dashboard"
          />
          <Route
            exact
            path="/login"
            component={LoginPage}
          />
          <Route
            exact
            path="/auth"
            component={LoggedInPage}
          />
          <Route
            path="/app"
            component={DefaultLayout}
          />

          {isFeatureEnabled('cwt') && (
            <Redirect
              exact
              from="/cwt"
              to="/cwt/wo"
            />
          )}
          {isFeatureEnabled('cwt') && (
            <Route
              path="/cwt"
              component={CWTLayout}
            />
          )}
          <Route path="*">
            <Redirect to="/app/dashboard" />
          </Route>
        </Switch>
      </ConnectedRouter>
    </ThemeProvider>
  </StylesProvider>
);

export default App;
