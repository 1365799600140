import { Box, Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import useScorecard from '../../../hooks/scorecard';
import CircularProgress from '@material-ui/core/CircularProgress';
import theme from '../../../theme';
interface Props {
  selectedElementId: number;
  year: number;
  quarter: number;
}

const ColorBox = styled.div`
  margin: 0;
  width: 100%;
  min-height: 50px;
  height: 100%;
  border: 1px solid #757575;
`;

const IndicatorBox = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const CurrentHealthBox = styled(Box)`
  max-height: 350px;
  overflow-y: scroll;
`;
const IndicatorScoresTable = ({ selectedElementId, year, quarter }: Props) => {
  const { scorecard, isLoading } = useScorecard(selectedElementId, year, quarter);
  const colourOrder = {
    red: 1,
    yellow: 2,
    white: 3,
    green: 4,
    gray: 5,
  };
  return (
    <CurrentHealthBox>
      <Grid container>
        {!isLoading &&
          scorecard.indicators?.map((indicator, index) => {
            const isEvenRow = index % 2 === 0;
            const rowBackgroundColor = isEvenRow ? '#f2f2f2' : '#ffffff';
            const indicatorDetails = scorecard.indicatorMap[indicator];
            const unitColors = Object.entries(scorecard.scores[indicator])
              .map(([unit, score]) => {
                if (scorecard.unitMap[unit].mcr) {
                  return colourOrder['gray'];
                }
                return colourOrder[score.colour as keyof typeof colourOrder];
              })
              .sort((a, b) => a - b);

            const indicatorColorName =
              unitColors[0] === 1
                ? 'red'
                : unitColors[0] === 2
                ? 'yellow'
                : unitColors[0] === 3
                ? 'white'
                : unitColors[0] === 4
                ? 'green'
                : 'grey';

            const indicatorColor = theme.colours.scorecard[indicatorColorName].background;

            return (
              <>
                <Grid
                  item
                  xs={9}
                  style={{ backgroundColor: rowBackgroundColor }}
                >
                  <IndicatorBox>
                    <Typography>{indicatorDetails.name}</Typography>
                  </IndicatorBox>
                </Grid>
                <Grid
                  item
                  xs={3}
                >
                  <ColorBox style={{ backgroundColor: indicatorColor }}></ColorBox>
                </Grid>
              </>
            );
          })}
      </Grid>
      {isLoading && <CircularProgress size={20} />}
    </CurrentHealthBox>
  );
};

export default IndicatorScoresTable;
