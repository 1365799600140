import React from 'react';
import useIndicator from '../../hooks/indicator';
import useIndicatorRecordsByQuarter from '../../hooks/indicator-records-by-quarter';
import useReviewIndicatorRecordApplicability from '../../hooks/review-indicator-record-applicability';
import ApplyDialog from '../ApplyDialog';
import RecordTables from '../IndicatorRecordTable/RecordTables';

interface Props {
  elementId: number;
  year: number;
  quarter: number;
  elementUnit?: string;
}

const RecordsTableModule = ({ elementId, year, quarter, elementUnit }: Props) => {
  const { id: indicatorId, shortName: indicatorShortName } = useIndicator();

  const { records, isLoading } = useIndicatorRecordsByQuarter(elementId, year, quarter, indicatorId);

  const {
    selectedRecord,
    recordType,
    setSelectedRecord,
    clearSelectedRecord,
    setSelectedRecordApplicable,
    setRecordInapplicable,
    setRecordApplicable,
  } = useReviewIndicatorRecordApplicability(elementId, year, quarter, indicatorId, indicatorShortName, records);
  return (
    <>
      <RecordTables
        isLoading={isLoading}
        records={
          elementUnit ? records.filter(({ elementUnit: elU }) => (elementUnit ? elU === elementUnit : true)) : records
        }
        onApply={setSelectedRecord}
        onDismiss={setRecordInapplicable}
        onApplicable={setRecordApplicable}
        noPromptApply={setSelectedRecord}
      />
      {selectedRecord && (
        <ApplyDialog
          indicatorShortName={recordType || indicatorShortName}
          record={selectedRecord}
          onSave={setSelectedRecordApplicable}
          onCancel={clearSelectedRecord}
        />
      )}
    </>
  );
};

export default RecordsTableModule;
