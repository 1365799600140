import { useState, useCallback } from 'react';
import * as R from 'ramda';

const useSelectedItem = <T>(items: T[], compareKey: Exclude<keyof T, symbol>) => {
  const [selectedItem, setSelectedItem] = useState<T | null>(null);

  const selectItem = useCallback(
    (compareValue: string) => {
      setSelectedItem(R.find(R.propEq(compareKey)(compareValue), items) ?? null);
    },
    [items, compareKey]
  );

  const clearSelectedItem = useCallback(() => {
    setSelectedItem(null);
  }, [setSelectedItem]);

  return {
    selectedItem,
    selectItem,
    clearSelectedItem,
  };
};

export default useSelectedItem;
