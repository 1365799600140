import { useEffect, useState } from 'react';
import api from '../../../../api';
import OverlayPaper from '../../../../components/OverlayPaper';
import useQuarters from '../../../../hooks/quarters';
import { Report } from '../../../../hooks/report';
import { ExecutiveSummary } from '../../../../types/executive-summary';
import { dateTimeToString } from '../../../../utils/date-format';
import ExecutiveSummaryForm, {
  ExecutiveSummaryFormValues,
} from '../../../health-reports/pages/executive-summary/ExecutiveSummaryForm';
import ExecutiveSummaryLayout from '../../../health-reports/pages/executive-summary/Layout';
import SummaryPanel from '../../../health-reports/pages/executive-summary/SummaryPanel';
import { TabProps } from '../../types';

const ExecutiveSummaryTab: React.FC<TabProps> = ({ element }) => {
  const { quarters } = useQuarters({ n: 1 });
  const [executiveSummary, setExecutiveSummary] = useState<ExecutiveSummary | null>(null);

  const summaryText = executiveSummary?.text || '';
  const lastSavedTime = executiveSummary?.validFrom && dateTimeToString(executiveSummary.validFrom);

  useEffect(() => {
    const fetchExecutiveSummary = async (elementId: string) => {
      const result: ExecutiveSummary | undefined = await api.getExecutiveSummary(elementId);
      setExecutiveSummary(result || null);
    };

    if (element.elementId) {
      void fetchExecutiveSummary(element.elementId);
    }
  }, [element.elementId]);

  const handleSave = async (value: string) => {
    const result = await api.updateExecutiveSummary(element.elementId, value);
    setExecutiveSummary(result);
  };

  const onAutoSave = async (value: string) => {
    if (element.elementId && value !== summaryText) {
      handleSave(value);
    }
  };

  const onSubmit = ({ executiveSummary }: ExecutiveSummaryFormValues) => handleSave(executiveSummary);

  // TODO: Can we build this (easily) without creating a fake report?
  const report = {
    elementId: element.elementId,
    elementType: element.elementType,
    quarter: quarters[0]?.quarter,
    year: quarters[0]?.year,
  } as unknown as Report;

  const RightPanel = ({ className }: { className?: string }) => (
    <OverlayPaper
      title="Executive Summary and Supporting Data"
      className={className}
      statusText={lastSavedTime && `Last saved at: ${lastSavedTime}`}
    >
      <ExecutiveSummaryForm
        initialValue={summaryText}
        saving={false}
        onSave={onAutoSave}
        onSubmit={onSubmit}
      />
    </OverlayPaper>
  );

  return (
    <ExecutiveSummaryLayout
      leftPanel={<SummaryPanel report={report} />}
      rightPanel={<RightPanel />}
    />
  );
};

export default ExecutiveSummaryTab;
