import React from 'react';
import { Control, Controller, FieldValues, Path, RegisterOptions } from 'react-hook-form';
import CustomNumberField from '../components/CustomNumberField';
import CustomTextField, { CustomTextFieldProps } from '../components/CustomTextField';

export interface ControlTextFieldProps<T extends FieldValues, P extends Path<T>>
  extends Pick<
      CustomTextFieldProps,
      | 'className'
      | 'disabled'
      | 'error'
      | 'fullWidth'
      | 'helperText'
      | 'inputProps'
      | 'label'
      | 'minRows'
      | 'multiline'
      | 'name'
      | 'onBlur'
      | 'onChange'
      | 'placeholder'
      | 'size'
      | 'type'
      | 'useDefaultLabel'
      | 'value'
      | 'variant'
    >,
    Pick<RegisterOptions<T, P>, 'deps' | 'required' | 'validate'> {
  control: Control<T>;
  required: boolean;
  name: P;
  error: boolean;
  maxLength?: number;
  minLength?: number;
}

export const ControlTextField = <T extends FieldValues, P extends Path<T>>({
  control,
  required,
  label,
  name,
  error,
  minLength,
  maxLength,
  placeholder,
  minRows,
  multiline = false,
  fullWidth = true,
  variant,
  size,
  type,
  helperText,
  disabled = false,
  validate,
  className,
  useDefaultLabel,
  onBlur,
  deps,
}: ControlTextFieldProps<T, P>) => (
  <Controller
    name={name}
    control={control}
    disabled={disabled}
    rules={{ required, validate, deps }}
    render={({ field }) => {
      const InputComponent = type === 'number' ? CustomNumberField : CustomTextField;
      return (
        <InputComponent
          className={className}
          multiline={multiline}
          fullWidth={fullWidth}
          onChange={field.onChange}
          value={field.value ?? ''}
          disabled={field.disabled}
          error={error}
          inputProps={{ maxLength, minLength, 'data-testid': name }}
          name={field.name}
          label={label}
          placeholder={placeholder}
          minRows={minRows}
          variant={variant}
          size={size}
          type={type}
          helperText={helperText}
          useDefaultLabel={useDefaultLabel}
          onBlur={onBlur}
        />
      );
    }}
  />
);
