import axios from 'axios';
import * as R from 'ramda';

const configWithAuthorization = (config, accessToken) => ({
  ...config,
  maxRedirects: 0,
  headers: {
    ...config.headers,
    authorization: `Bearer ${accessToken}`,
  },
});

const get =
  authProvider =>
  (url, config = {}) =>
    authProvider
      .getAccessToken()
      .then(token => axios.get(url, configWithAuthorization(config, token.accessToken)))
      .then(R.prop('data'));

const post =
  authProvider =>
  (url, data = {}, config = {}) =>
    authProvider
      .getAccessToken()
      .then(token => axios.post(url, data, configWithAuthorization(config, token.accessToken)))
      .then(R.prop('data'));

const del =
  authProvider =>
  (url, config = {}) =>
    authProvider
      .getAccessToken()
      .then(token => axios.delete(url, configWithAuthorization(config, token.accessToken)))
      .then(R.prop('data'));

const patch =
  authProvider =>
  (url, data = {}, config = {}) =>
    authProvider
      .getAccessToken()
      .then(token => axios.patch(url, data, configWithAuthorization(config, token.accessToken)))
      .then(R.prop('data'));

const put =
  authProvider =>
  (url, data = {}, config = {}) =>
    authProvider
      .getAccessToken()
      .then(token => axios.put(url, data, configWithAuthorization(config, token.accessToken)))
      .then(R.prop('data'));

const download =
  authProvider =>
  (url, config = {}) =>
    authProvider
      .getAccessToken()
      .then(token =>
        axios.get(
          url,
          configWithAuthorization(
            {
              ...config,
              responseType: 'blob',
              timeout: 30000,
            },
            token.accessToken
          )
        )
      )
      .then(R.prop('data'));

export default authProvider => ({
  get: get(authProvider),
  post: post(authProvider),
  del: del(authProvider),
  patch: patch(authProvider),
  put: put(authProvider),
  download: download(authProvider),
});
