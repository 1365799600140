import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { Scorecard } from 'echo-scorecard';
import ScorecardTable from '../Scorecard/ScorecardTable';
import useScorecard from '../../hooks/scorecard';
import { ScorecardSectionsProvider } from '../../hooks/scorecard-sections';
import UnstyledTotalScore from '../Scorecard/TotalScore';
import useEditManualTotalValue from '../../hooks/edit-manual-total-value';
import TotalValueOverrideDialog from '../Scorecard/TotalValueOverrideDialog';
import useEditManualUnitTotalValue from '../../hooks/edit-manual-unit-total-value';
import ManualValueDialog from '../Scorecard/ManualValueDialog';
import useOverrideValue from '../../hooks/override-value';
import IndicatorDetailsDialog from '../Scorecard/IndicatorDetailsDialog';
import useEditManualIndicatorValue from '../../hooks/edit-manual-indicator-value';
import { Report } from '../../hooks/report';
import ManualProgramIndicatorValueDialog from '../Scorecard/ManualProgramIndicatorValueDialog';

interface Props {
  className: string;
  report: Report;
}

const TotalScore = styled(UnstyledTotalScore)``;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 8px;

  ${TotalScore} {
    align-self: flex-end;
    width: 200px;
  }
`;

const ScorecardContainer = ({ className, report }: Props) => {
  const { elementId, year, quarter, elementType, facilityId } = report;
  const { scorecard } = useScorecard(report.elementId, parseInt(report.year, 10), parseInt(report.quarter, 10));
  const overrideTotal: any = !R.isEmpty(report)
    ? {
        score: report?.overriddenScore ? report.overriddenScore : R.path(['totals', 'score'], scorecard),
        colour: report?.overriddenColour ? report.overriddenColour : R.path(['totals', 'colour'], scorecard),
        comment: report.overrideComment,
      }
    : null;
  const { overrideValue, editOverrideValue, cancelEditOverrideValue } = useOverrideValue(
    elementId,
    parseInt(year, 10),
    parseInt(quarter, 10),
    scorecard
  );
  const { manualIndicatorValue, editManualIndicatorValue, cancelEditManualIndicatorValue } =
    useEditManualIndicatorValue(elementId, year, quarter, scorecard);
  const { manualTotalValue, editManualTotalValue, cancelEditManualTotalValue } = useEditManualTotalValue({
    reportId: report.id,
    scorecard,
    overrideTotal,
  });
  const { manualUnitTotalValue, editManualUnitTotalValue, cancelEditManualUnitTotalValue } =
    useEditManualUnitTotalValue({ elementId, year: parseInt(year, 10), quarter: parseInt(quarter, 10), scorecard });

  return (
    <ScorecardSectionsProvider elementId={report.elementId}>
      {!R.isEmpty(scorecard) && (
        <Container>
          <TotalScore
            report={report}
            scorecard={scorecard as Scorecard}
            onClick={editManualTotalValue}
          />
          <ScorecardTable
            className={className}
            scorecard={scorecard}
            editable={true}
            onCalculatedScoreClicked={editOverrideValue}
            onManualScoreClicked={editManualIndicatorValue}
            onUnitTotalScoreClicked={editManualUnitTotalValue}
          />
        </Container>
      )}
      {overrideValue && (
        <IndicatorDetailsDialog
          readonly
          elementType={elementType}
          facilityId={facilityId}
          overrideValue={overrideValue}
          elementId={elementId}
          year={parseInt(year, 10)}
          quarter={parseInt(quarter, 10)}
          onClear={() => null}
          onCancel={cancelEditOverrideValue}
          onSave={() => null}
        />
      )}
      {!R.isNil(manualTotalValue) && (
        <TotalValueOverrideDialog
          overrideValue={manualTotalValue}
          readonly
          onSave={() => null}
          onCancel={cancelEditManualTotalValue}
        />
      )}
      {manualIndicatorValue && (report.elementType === 'program' || elementType === 'program') && (
        <ManualProgramIndicatorValueDialog
          readonly
          elementId={report.elementId}
          manualValue={manualIndicatorValue}
          onCancel={cancelEditManualIndicatorValue}
          onSave={() => null}
        />
      )}
      {manualUnitTotalValue && (
        <ManualValueDialog
          title="Total Unit Value Override"
          readonly
          manualValue={manualUnitTotalValue as any}
          onSave={() => null}
          onCancel={cancelEditManualUnitTotalValue}
        />
      )}
      {manualIndicatorValue && (report.elementType !== 'program' || elementType !== 'program') && (
        <ManualValueDialog
          readonly
          manualValue={manualIndicatorValue}
          onCancel={cancelEditManualIndicatorValue}
          onSave={() => null}
        />
      )}
    </ScorecardSectionsProvider>
  );
};

export default ScorecardContainer;
