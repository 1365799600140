import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import * as R from 'ramda';

import DownloadButton from '../../../../../components/DownloadButton';
import UnstyledTotalScoreBox from '../../../../../components/Scorecard/TotalScoreBox';
import useScorecard from '../../../../../hooks/scorecard';
import useReport from '../../../../../hooks/report';

interface Props {
  theReport: any;
}

const TotalScoreBox = styled(UnstyledTotalScoreBox)`
  height: 33px;
  width: 107px;
  margin-left: 22px;
`;

const section = {
  height: '100%',
  paddingTop: 45,
};

const TopScore = ({ theReport }: Props) => {
  const { generateAndDownloadDraftReport } = useReport(theReport.id);
  const scorecard = useScorecard(theReport.elementId, theReport.year, theReport.quarter);
  const totalScore: any = R.path(['scorecard', 'totals', 'score'], scorecard);
  const scoreColour: any = R.path(['scorecard', 'totals', 'colour'], scorecard);

  return (
    <Grid
      xs={8}
      spacing={2}
      item
      container
      direction="row"
      alignItems="center"
      style={{
        justifyContent: 'flex-end',
      }}
    >
      {totalScore && scoreColour && (
        <>
          <Grid item>Most Recent Report: {`Q${theReport.quarter} ${theReport.year}`}</Grid>
          <TotalScoreBox
            overridden={false}
            totalScore={totalScore}
            scoreColour={scoreColour}
          />
          <Grid item>
            <DownloadButton onClick={generateAndDownloadDraftReport} />
          </Grid>
        </>
      )}
      {!(totalScore && scoreColour) && (
        <Grid
          item
          style={section}
        >
          {' '}
          Loading Previous Report
        </Grid>
      )}
    </Grid>
  );
};

export default TopScore;
