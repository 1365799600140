import React from 'react';
import styled from 'styled-components';
import { menuItems } from './menu-items';
import MenuList from './MenuList';

import authProvider from '../../auth/authProvider';
import { useRiskManagementUsers } from '../../context/business-equipment-risk-admin';

const Container = styled.div``;

const Menu = (props: any) => {
  const currentUserRoles = useRiskManagementUsers();
  const entries = menuItems(authProvider.logout, currentUserRoles);
  return (
    <Container
      {...props}
      style={{ overflowX: 'hidden' }}
    >
      <MenuList entries={entries} />
    </Container>
  );
};

export default Menu;
