import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import elementTypePrefix from '../../../utils/element-type-prefix';
import { MyElement } from '../../../types/my';
import Api from '../../../api';
interface useDashboardPageValue {
  elements: Element[];
  selectedElementId: number | null;
  handleSetSelectedElementId: (elementId: number) => void;
  loadingElements: boolean;
  loadingIndicators: boolean;
  activeView: string;
  indicator: Indicator | undefined;
  showFunctionalFailures: () => void;
  showOutstandingCorrectiveActions: () => void;
}
interface Element {
  label: string;
  value: number;
  type: string;
}
interface Indicator {
  id: number;
  description: string;
  element_type: string;
  name: number;
  short_name: string;
  type_id: string;
}

const DashboardPageContext = createContext<useDashboardPageValue | undefined>(undefined);

export const DashboardPageContextProvider = ({ children }: React.PropsWithChildren) => {
  const [elements, setElements] = useState<Element[]>([]);
  const [selectedElementId, setSelectedElementId] = useState<number | null>(null);
  const [loadingElements, setLoadingElements] = useState(true);

  const [loadingIndicators, setLoadingIndicators] = useState(true);
  const [indicator, setIndicator] = useState<Indicator | undefined>();
  const [activeView, setActiveView] = useState('functional-failures');

  const showFunctionalFailures = useCallback(() => {
    setActiveView('functional-failures');
  }, [setActiveView]);

  const showOutstandingCorrectiveActions = useCallback(() => {
    setActiveView('outstanding-corrective-actions');
  }, [setActiveView]);

  const handleSetSelectedElementId = async (elementId: number | null) => {
    await setSelectedElementId(elementId);
  };

  const viewIndicatorShortNames = {
    'functional-failures': ['functional-failures', 'comp-functional-failures'],
    'outstanding-corrective-actions': ['outstanding-corrective-actions', 'comp-outstanding-corrective-actions'],
  };

  useEffect(() => {
    const loadPageData = async () => {
      setLoadingIndicators(true);
      if (activeView === 'functional-failures' || activeView === 'outstanding-corrective-actions') {
        const indicators = await Api.getIndicatorsByShortName(viewIndicatorShortNames[activeView]);

        const elementType = elements.find((e: any) => e.value === selectedElementId)?.type;

        if (elementType) {
          const newIndicator = indicators.find((i: any) => i.element_type === elementType);
          setIndicator(newIndicator);
        }

        setLoadingIndicators(false);
      }
    };
    void loadPageData();
  }, [selectedElementId, activeView]);

  useEffect(() => {
    const getAssignments = async () => {
      const assignments = await Api.getMyAssignments();
      const elements = assignments.map((assignment: MyElement) => ({
        label: `[${elementTypePrefix(assignment.elementType)}] ${assignment.elementName} (${assignment.facilityName})`,
        value: assignment.elementId,
        type: assignment.elementType,
      }));
      setElements(elements);
      handleSetSelectedElementId(elements?.[0]?.value ?? null);
      setLoadingElements(false);
    };
    if (loadingElements) {
      void getAssignments();
    }
  }, [loadingElements]);

  return (
    <DashboardPageContext.Provider
      value={{
        elements,
        selectedElementId,
        handleSetSelectedElementId,
        loadingElements,
        loadingIndicators,
        activeView,
        indicator,
        showFunctionalFailures,
        showOutstandingCorrectiveActions,
      }}
    >
      {children}
    </DashboardPageContext.Provider>
  );
};

export const useDashboardPage = () => {
  const context = useContext(DashboardPageContext);
  if (context === undefined) {
    throw new Error('useDashboardPage must be used within a DashboardPageProvider');
  }
  return context;
};
