import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { Logger, LogLevel } from 'msal';

// The auth provider should be a singleton. Best practice is to only have it ever instantiated once.
// Avoid creating an instance inside the component it will be recreated on each render.
// If two providers are created on the same page it will cause authentication errors.
const authProvider = new MsalAuthProvider(
  {
    auth: {
      authority: `https://login.microsoftonline.com/${window.echoConfig.tenantId}`,
      clientId: window.echoConfig.clientId,
      redirectUri: window.echoConfig.redirectUri,
      validateAuthority: true,

      // After being redirected to the "redirectUri" page, should user
      // be redirected back to the Url where their login originated from?
      navigateToLoginRequestUrl: true,
    },
    // Enable logging of MSAL events for easier troubleshooting.
    // This should be disabled in production builds.
    system: {
      logger: new Logger(
        (_, message) => {
          // eslint-disable-line no-unused-vars
          console.log('[MSAL]', message); // eslint-disable-line no-console
        },
        {
          level: LogLevel.Warning,
          piiLoggingEnabled: false,
        }
      ),
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
    },
  },
  {
    scopes: window.echoConfig.scopes,
  },
  {
    loginType: LoginType.Redirect,
    // When a token is refreshed it will be done by loading a page in an iframe.
    // Rather than reloading the same page, we can point to an empty html file which will prevent
    // site resources from being loaded twice.
    tokenRefreshUri: `${window.location.origin}/auth`,
  }
);

export default authProvider;
