import React from 'react';
import styled from 'styled-components';
import { menuItems } from './menu-items';
import MenuList from './MenuList';

import authProvider from '../../auth/authProvider';

const Container = styled.div``;

const Menu = (props: any) => {
  const entries = menuItems(authProvider.logout);
  return (
    <Container
      {...props}
      style={{ overflowX: 'hidden' }}
    >
      <MenuList entries={entries} />
    </Container>
  );
};

export default Menu;
