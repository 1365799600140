import { Grid } from '@material-ui/core';
import useReports from '../../../../../hooks/reports';
import PastScore from './PastScore';

interface Props {
  selectedElement: any;
}

const RecentReportDetails = ({ selectedElement }: Props) => {
  const inProgressStates: any = ['unsubmitted', 'pending-review', 'changes-requested'];
  const reports: any[] = useReports(inProgressStates as any, true).reports;

  const theReport = reports.find(report => {
    return report.elementId == selectedElement || report.elementName == selectedElement;
  });

  const section = {
    height: '100%',
    paddingTop: 45,
  };

  return (
    <Grid
      xs={12}
      spacing={4}
      item
      container
      direction="row"
      justifyContent="flex-end"
      style={{
        marginRight: '2em',
      }}
    >
      {!theReport && (
        <Grid
          item
          style={section}
        >
          {' '}
          No Previous Report Found
        </Grid>
      )}
      {theReport && <PastScore theReport={theReport} />}
    </Grid>
  );
};

export default RecentReportDetails;
