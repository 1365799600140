import { fork } from 'redux-saga/effects';
import actionsSaga from './actions/sagas';
import customIndicatorsSaga from './custom-indicators/sagas';
import dashboardsSaga from './dashboards/sagas';
import elementsSaga from './elements/sagas';
import elementAttachmentsSaga from './element-attachments/sagas';
import facilitiesSaga from './facilities/sagas';
import indicatorsSaga from './indicators/sagas';
import indicatorRecordsSaga from './indicator-records/sagas';
import mySaga from './my/sagas';
import scorecardSaga from './scorecard/sagas';
import scorecardColoursSaga from './scorecard-colours/sagas';
import recordsSummarySaga from './records-summary/sagas';
import reportSaga from './reports/sagas';
import totalScoreColoursSaga from './total-score-colours/sagas';
import userSaga from './users/sagas';
import adminSaga from '../pages/admin/state/sagas';
import dashboardSaga from '../pages/dashboard/state/sagas';
import healthReportsSaga from '../pages/health-reports/state/sagas';
import recordDetailsSaga from '../pages/live-reports/tabs/review-records/state/sagas';

function* pagesSaga() {
  yield fork(adminSaga);
  yield fork(dashboardSaga);
  yield fork(healthReportsSaga);
  yield fork(recordDetailsSaga);
}

function* rootSaga() {
  yield fork(actionsSaga);
  yield fork(customIndicatorsSaga);
  yield fork(dashboardsSaga);
  yield fork(elementsSaga);
  yield fork(elementAttachmentsSaga);
  yield fork(facilitiesSaga);
  yield fork(indicatorsSaga);
  yield fork(indicatorRecordsSaga);
  yield fork(mySaga);
  yield fork(scorecardSaga);
  yield fork(scorecardColoursSaga);
  yield fork(recordsSummarySaga);
  yield fork(reportSaga);
  yield fork(totalScoreColoursSaga);
  yield fork(userSaga);
  yield fork(pagesSaga);
}

export default rootSaga;
