import React, { useState } from 'react';
import styled from 'styled-components';

import MUIDialog from '@material-ui/core/Dialog';
import MUIDialogContent from '@material-ui/core/DialogContent';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import UnAppBar from '@material-ui/core/AppBar';
import OverrideIndicatorDialog from '../OverrideIndicatorDialog';
import PreviousScoreDialog from '../PreviousScoreDialog';
import UnstyledCloseButton from '../CloseButton';
import { OverrideValue } from '../../hooks/override-value';
import { IndicatorProvider } from '../../hooks/indicator';
import useConditionalConfirm from '../../hooks/conditional-confirm';
import OkayCancelDialog from '../OkayCancelDialog';
import { PreviousScoreProvider } from '../../hooks/previous-score';
import OverrideDetails from './OverrideDetails';
import { OverrideDetailsProvider } from '../../hooks/override-details';
import RecordsTableModule from '../ReviewRecordsModule';

const Dialog = styled(MUIDialog)``;
const DialogContent = styled(MUIDialogContent)`
  height: 50vh;
`;

const AppBar = styled(UnAppBar)`
  background-color: ${({ theme }) => theme.colours.teals.teal1};
`;

const CloseButton = styled(UnstyledCloseButton)`
  color: white;
`;
interface Props {
  isLive?: boolean;
  readonly?: boolean;
  overrideValue: OverrideValue;
  facilityId: string;
  elementId: number;
  elementType?: string;
  year: number;
  quarter: number;
  onSave: any;
  onCancel: () => void;
  onClear: () => void;
}

const IndicatorDetailsDialog = ({
  isLive = false,
  readonly,
  overrideValue,
  facilityId,
  elementId,
  elementType,
  year,
  quarter,
  onSave,
  onCancel,
  onClear,
}: Props) => {
  const [isDirty, setIsFormDirty] = useState(false);
  const [value, setValue] = useState(0);

  const {
    confirming,
    confirm: confirmCancel,
    onConfirm: onConfirmCancel,
    onCancel: onCancelConfirm,
  } = useConditionalConfirm(onCancel, isDirty);

  return (
    <>
      <Dialog
        open
        onClose={confirmCancel}
        fullWidth
        maxWidth="md"
      >
        <AppBar position="relative">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              xs={1}
              container
              justifyContent="center"
              alignItems="center"
            >
              <CloseButton onClick={confirmCancel} />
            </Grid>
            <Grid
              item
              xs
            >
              <Tabs
                value={value}
                onChange={(_, newValue: number) => setValue(newValue)}
              >
                <Tab label="Details" />
                <Tab
                  label="Override"
                  style={{ display: isLive ? 'none' : 'block' }}
                />
                <Tab label="Previous Score" />
                <Tab
                  label="Override Details"
                  style={{ display: isLive ? 'none' : 'block' }}
                />
              </Tabs>
            </Grid>
          </Grid>
        </AppBar>
        <DialogContent>
          {value === 0 && (
            <IndicatorProvider indicatorId={overrideValue.indicatorId}>
              <RecordsTableModule
                elementId={elementId}
                quarter={quarter}
                year={year}
                elementUnit={overrideValue.elementUnit}
              />
            </IndicatorProvider>
          )}
          {value === 1 && (
            <OverrideDetailsProvider
              indicatorName={overrideValue.indicatorName}
              indicatorId={overrideValue.indicatorId}
              facilityId={facilityId}
              elementUnit={overrideValue.elementUnit}
              elementId={elementId}
              elementType={elementType}
              year={year}
              quarter={quarter}
            >
              <OverrideIndicatorDialog
                readonly={readonly}
                overrideValue={overrideValue}
                onSetFormDirty={setIsFormDirty}
                onSave={onSave}
                onClear={onClear}
              />
            </OverrideDetailsProvider>
          )}
          {value === 2 && (
            <PreviousScoreProvider
              indicatorId={overrideValue.indicatorId}
              elementUnit={overrideValue.elementUnit}
              elementId={elementId}
              year={year}
              quarter={quarter}
            >
              <PreviousScoreDialog indicatorName={overrideValue.indicatorName} />
            </PreviousScoreProvider>
          )}
          {value === 3 && (
            <OverrideDetails
              indicatorName={overrideValue.indicatorName}
              indicatorId={overrideValue.indicatorId}
              facilityId={facilityId}
              elementUnit={overrideValue.elementUnit}
              elementType={elementType}
              elementId={elementId}
              year={year}
              quarter={quarter}
            />
          )}
        </DialogContent>
      </Dialog>
      {confirming && (
        <OkayCancelDialog
          title="Cancel?"
          okayLabel="Continue"
          onOkay={onConfirmCancel}
          onCancel={onCancelConfirm}
        >
          Are you sure? If you choose to continue, you will lose the data you have entered.
        </OkayCancelDialog>
      )}
    </>
  );
};

export default IndicatorDetailsDialog;
