import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import * as R from 'ramda';
import { useRouteMatch } from 'react-router-dom';
import UnstyledPaper from '@material-ui/core/Paper';
import { Downloader } from '../../../../hooks/downloader';
import StartReportDialog from '../../components/StartReportDialog';
import ReportStateChangeCommentDialog from '../../../../components/ReportStateChangeCommentDialog';
import FilterPanel from './FilterPanel';
import ReportList from './ReportList';
import useReports from '../../../../hooks/reports';
import useReportStarter from '../../hooks/report-starter';
import Page from '../../../../components/Page';
import * as reportActions from '../../../../state/reports/actions';
import FilterOptionsPanel from './FilterOptionsPanel';
import HistoricalReportList from './HistoricalReportList';
import { HistoricalReportsProvider } from '../../hooks/historical-reports';
import HistoricalReportFilter from '../../../../types/historical-report-filter';
import { CircularProgress } from '@material-ui/core';

const Paper = styled(UnstyledPaper).attrs({
  square: true,
})``;

const tabToStates: any = {
  'in-progress': ['unsubmitted', 'pending-review', 'changes-requested'],
  archived: ['approved'],
};

const useCloseReport = (reports: any) => {
  const dispatch = useDispatch();
  const [closingReport, setClosingReport] = useState(null);

  const confirmCloseReport = useCallback(
    (reportId: any) => {
      const report = R.find(R.propEq('id', reportId), reports);
      setClosingReport(report as any);
    },
    [reports, setClosingReport]
  );

  const cancelCloseReport = useCallback(() => {
    setClosingReport(null);
  }, [setClosingReport]);

  const closeReport = useCallback(() => {
    dispatch(reportActions.closeRequest(closingReport, 'Yup'));
    setClosingReport(null);
  }, [dispatch, closingReport, setClosingReport]);

  return {
    closingReport,
    confirmCloseReport,
    cancelCloseReport,
    closeReport,
  };
};

const LandingPage = () => {
  const [currentTab, setCurrentTab] = useState('in-progress');
  const [filters, setFilters] = useState({
    elementName: 'none',
    facility: 'none',
    elementType: 'none',
    quarter: 'none',
    year: 'none',
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const { url } = useRouteMatch();
  const { reports, getFilteredReports, years, isLoading } = useReports(tabToStates[currentTab] as any, true);
  const { startReport } = useReportStarter();
  const { closingReport, confirmCloseReport, cancelCloseReport, closeReport } = useCloseReport(reports);

  const handleStartReport = (elementId: string, year: string, quarter: string) => {
    startReport(elementId, year, quarter);
    setDialogOpen(false);
  };

  const [historicalFilters, setHistoricalFilter] = useState<HistoricalReportFilter>({
    elementName: '',
    elementType: '',
    facilityId: '',
    year: '',
    quarter: '',
  });

  return (
    <Page
      title="Health Reports"
      bodyComponent={<Paper square />}
    >
      <FilterPanel
        onChangeTab={setCurrentTab}
        onStartNew={() => setDialogOpen(true)}
        currentTab={currentTab}
      />
      {currentTab !== 'historical' && (
        <FilterOptionsPanel
          onChangeFilter={(updatedFilter: any) => {
            setFilters({ ...filters, ...updatedFilter });
          }}
          yearOptions={years}
        />
      )}
      {!isLoading && (
        <Downloader>
          {currentTab === 'in-progress' && (
            <ReportList
              reports={getFilteredReports(filters)}
              baseUrl={url}
              onCloseReport={confirmCloseReport}
            />
          )}

          {currentTab === 'archived' && (
            <ReportList
              reports={getFilteredReports(filters)}
              baseUrl={url}
            />
          )}

          {currentTab === 'historical' && (
            <HistoricalReportsProvider filters={historicalFilters}>
              <HistoricalReportList
                filters={historicalFilters}
                setFilters={setHistoricalFilter}
              />
            </HistoricalReportsProvider>
          )}
        </Downloader>
      )}
      {isLoading && (
        <div>
          <CircularProgress style={{ marginLeft: '35vw', marginTop: '30vh', height: '5%', width: '5%' }} />
        </div>
      )}
      {dialogOpen && (
        <StartReportDialog
          open={true}
          onStartReport={handleStartReport}
          onCancel={() => setDialogOpen(false)}
        />
      )}
      {closingReport && (
        <ReportStateChangeCommentDialog
          title="Close Report"
          commentRequired={true}
          message="WARNING: You are about to close this report. This change is irreversible."
          onCancel={cancelCloseReport}
          onSave={closeReport}
        />
      )}
    </Page>
  );
};

export default LandingPage;
