import { reduce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Control, FieldErrors, SubmitHandler, UseFormWatch, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Api from '../../../api';
import { AutocompleteOption } from '../../../components/AutocompleteInput';
import * as uiActions from '../../../state/ui/actions';
import {
  ApplicableUnits,
  Category,
  DataSource,
  Disposition,
  Facilities,
  MaximoOutage,
  Outage,
  OutageFormInputs,
  Resolution,
  Units,
} from '../../../types/outages';
import { getUnitsForFacility } from '../../../utils/units';
import { durationDays, durationHours } from '../utils/duration';
import { getDispositionOptions } from '../utils/getDispositionOptions';
import { toOptions } from '../utils/toOptions';

const FACILITY_OPTIONS: AutocompleteOption<Facilities>[] = [
  { label: 'BA', value: 'BA' },
  { label: 'BB', value: 'BB' },
];

const CATEGORY_OPTIONS: AutocompleteOption<Category>[] = [
  { label: 'Equipment', value: 'equipment' },
  { label: 'Grid', value: 'grid' },
  { label: 'HU', value: 'hu' },
  { label: 'Outage Extension', value: 'outage_extension' },
  { label: 'Unknown', value: 'unknown' },
];

const RESOLUTION_OPTIONS: AutocompleteOption<Resolution>[] = [
  { label: 'Resolution Required', value: 'resolution_required' },
  { label: 'Resolution in Progress', value: 'resolution_in_progress' },
  { label: 'Resolved', value: 'resolved' },
];

const APPLICABLE_UNITS_LABELS: Record<keyof ApplicableUnits, string> = {
  '0A': 'Unit 0A',
  U1: 'Unit 1',
  U2: 'Unit 2',
  U3: 'Unit 3',
  U4: 'Unit 4',
  '0B': 'Unit 0B',
  U5: 'Unit 5',
  U6: 'Unit 6',
  U7: 'Unit 7',
  U8: 'Unit 8',
};

interface OutageForm {
  control: Control<OutageFormInputs>;
  handleSubmit: () => Promise<void>;
  errors: FieldErrors<OutageFormInputs>;
  outageCodeOptions: AutocompleteOption[];
  facilityOptions: AutocompleteOption<Facilities>[];
  categoryOptions: AutocompleteOption<Category>[];
  resolutionOptions: AutocompleteOption<Resolution>[];
  dispositionOptions: AutocompleteOption<Disposition>[];
  unitOptions: AutocompleteOption<Units>[];
  applicableUnitLabels: Record<keyof ApplicableUnits, string>;
  dataSource: DataSource;
  durationDays: number | null;
  durationHours: number | null;
  watch: UseFormWatch<OutageFormInputs>;
}

export const useOutageForm = (handleClose: () => void, outage?: Outage): OutageForm => {
  const initialValues = useMemo<OutageFormInputs>(() => {
    return {
      outageCode: outage?.outage_code || null,
      facility: outage?.facility || null,
      unit: outage?.unit || null,
      startDate: outage?.start_date ? new Date(outage.start_date) : null,
      endDate: outage?.end_date ? new Date(outage.end_date) : null,
      relatedSCR: outage?.related_scr || null,
      category: outage?.category || null,
      sbgInclusion: outage?.sbg_include ?? false,
      sbgInclusionDays: outage?.sbg_inclusion_days ?? null,
      resolution: outage?.resolution_status || null,
      disposition: outage?.disposition || null,
      system: outage?.system || '',
      component: outage?.component || '',
      repeatOutage: outage?.repeat_outage ?? false,
      eventDescription: outage?.event_description || '',
      verified: outage?.verified ?? false,
      comments: outage?.comments || '',
      applicableUnits: (<Units[]>['0A', 'U1', 'U2', 'U3', 'U4', '0B', 'U5', 'U6', 'U7', 'U8']).reduce(
        (formState, unitName) => ({
          ...formState,
          [unitName]: outage?.applicable_units?.includes(unitName) ?? false,
        }),
        {} as ApplicableUnits
      ),
    };
  }, [outage]);

  const [outageCodeOptions, setOutageCodeOptions] = useState<AutocompleteOption[]>([]);
  const [outages, setOutages] = useState<MaximoOutage[]>([]);
  const [unitOptions, setUnitOptions] = useState<AutocompleteOption<Units>[]>([]);
  const [dataSource, setDataSource] = useState<DataSource>((outage?.data_source as DataSource) || 'manual');
  const [dispositionOptions, setDispositionOptions] = useState<AutocompleteOption<Disposition>[]>(
    getDispositionOptions(initialValues.resolution || null)
  );

  const fetchOutageCodeOptions = async () => {
    const response = await Api.getMaximoOutages();
    setOutageCodeOptions(
      toOptions(
        response.filter(outage => !!outage.outage),
        'outage'
      )
    );
    setOutages(response);
  };

  useEffect(() => {
    void fetchOutageCodeOptions();
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
    resetField,
    reset,
  } = useForm<OutageFormInputs>({
    defaultValues: initialValues,
    mode: 'all',
  });

  // Ensure that initial values are preloaded
  useEffect(() => {
    reset(initialValues);
  }, [initialValues]);

  const resetOutageDependentFields = () => {
    setDataSource('manual');
    resetField('facility');
    resetField('unit');
    resetField('startDate');
    resetField('endDate');
  };

  useEffect(() => {
    const outageCode = getValues('outageCode');
    const matchingOutage = outages.find(outage => outage.outage === outageCode);

    // Outage code can't be changed if we're editing an existing outage, so don't run dependent logic
    if (outage) {
      return;
    }

    if (!matchingOutage) {
      resetOutageDependentFields();
      return;
    }

    const { end_date, site, start_date, unit } = matchingOutage;

    // Validate matching outage data
    const matchingSiteOption = site
      ? (FACILITY_OPTIONS.find(option => option.value === site)?.value as Facilities)
      : null;
    const matchingUnitValue = unit
      ? (getUnitsForFacility(matchingSiteOption || 'All').find(option => option.includes(unit)) as Units)
      : null;
    const matchingStartDate = start_date && !Number.isNaN(new Date(start_date).valueOf()) ? new Date(start_date) : null;
    const matchingEndDate = end_date && !Number.isNaN(new Date(end_date).valueOf()) ? new Date(end_date) : null;

    if (matchingSiteOption && matchingUnitValue && matchingStartDate && matchingEndDate) {
      setDataSource('maximo');
      setValue('facility', matchingSiteOption);
      setValue('unit', matchingUnitValue);
      setValue('startDate', matchingStartDate);
      setValue('endDate', matchingEndDate);
    } else {
      resetOutageDependentFields();
    }
  }, [watch('outageCode')]);

  useEffect(() => {
    const [facilityValue, unitValue] = getValues(['facility', 'unit']);
    const allowedUnits = facilityValue ? getUnitsForFacility(facilityValue) : [];

    // Reset unit selection if not valid
    if (unitValue && !allowedUnits.includes(unitValue)) {
      resetField('unit');
    }

    setUnitOptions((allowedUnits as Units[]).map(unit => ({ label: unit, value: unit })));
  }, [watch('facility')]);

  useEffect(() => {
    // Reset sbgInclusionDays value when sbgInclusion is toggled
    const sbgInclusionDaysValue = getValues('sbgInclusionDays');
    if (sbgInclusionDaysValue !== null) {
      resetField('sbgInclusionDays');
    }
  }, [watch('sbgInclusion')]);

  useEffect(() => {
    const [resolutionValue, dispositionValue] = getValues(['resolution', 'disposition']);
    const allowedDispositions = getDispositionOptions(resolutionValue || null);

    // Reset disposition selection if not valid
    if (dispositionValue && !allowedDispositions.find(option => option.value === dispositionValue)) {
      resetField('disposition');
    }

    setDispositionOptions(allowedDispositions);
  }, [watch('resolution')]);

  const dispatch = useDispatch();

  const onSubmit: SubmitHandler<OutageFormInputs> = async data => {
    const {
      outageCode,
      facility,
      unit,
      startDate,
      endDate,
      relatedSCR,
      category,
      sbgInclusion,
      sbgInclusionDays,
      resolution,
      disposition,
      system,
      component,
      repeatOutage,
      eventDescription,
      verified,
      comments,
      applicableUnits,
    } = data;

    if (
      !outageCode ||
      !facility ||
      !unit ||
      !startDate ||
      !endDate ||
      !relatedSCR ||
      !category ||
      !resolution ||
      !disposition ||
      !system ||
      !component ||
      !eventDescription ||
      !comments
    ) {
      return;
    }

    try {
      const payload = {
        facility,
        unit,
        start_date: startDate,
        end_date: endDate,
        category,
        sbg_include: !!sbgInclusion,
        sbg_inclusion_days: sbgInclusionDays ?? undefined,
        resolution_status: resolution,
        disposition,
        system,
        component,
        repeat_outage: !!repeatOutage,
        event_description: eventDescription,
        verified: !!verified,
        comments,
        data_source: dataSource,
        related_scr: relatedSCR,
        applicable_units: reduce<ApplicableUnits, string[]>(
          applicableUnits,
          (result, isApplicable, unit) => (isApplicable ? [...result, unit] : result),
          []
        ) as Units[],
      };

      if (outage) {
        await Api.updateOutage(outageCode, payload);
        dispatch(uiActions.genericMessage('Outage updated!'));
      } else {
        await Api.addOutage({
          outage_code: outageCode,
          ...payload,
        });
        dispatch(uiActions.genericMessage('Outage added!'));
      }
      handleClose();
    } catch (error: any) {
      console.error(error);
      dispatch(
        uiActions.error(
          '',
          (error?.response?.status === 400 && error?.response?.data?.message) ||
            `Error ${outage ? 'updating' : 'adding'} outage`
        )
      );
    }
  };

  const durationHoursValue = useMemo(() => {
    const startDate = getValues('startDate');
    const endDate = getValues('endDate');
    return durationHours(startDate, endDate);
  }, [watch('startDate'), watch('endDate')]);

  const durationDaysValue = useMemo(() => {
    const startDate = getValues('startDate');
    const endDate = getValues('endDate');
    return durationDays(startDate, endDate);
  }, [watch('startDate'), watch('endDate')]);

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    facilityOptions: FACILITY_OPTIONS,
    outageCodeOptions,
    unitOptions,
    categoryOptions: CATEGORY_OPTIONS,
    resolutionOptions: RESOLUTION_OPTIONS,
    dispositionOptions,
    applicableUnitLabels: APPLICABLE_UNITS_LABELS,
    dataSource,
    durationDays: durationDaysValue,
    durationHours: durationHoursValue,
    watch,
  };
};
