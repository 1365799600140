import * as R from 'ramda';
import * as actionTypes from '../action-types';

/*
{
  all: {
    reports: [],
    isLoading: true/false,
    error,
  },
  byId: {
    [reportId]: {
      report,
      isLoading: true/false,
      isSaving: true/false,
      error,
    },
  },
  comments: {
    [reportId]: {
      comments: [
        {
          comment,
          isSaving: true/false,
          error,
        },
      ],
      isLoading: true/false,
      error,
    },
  },
};
*/

const initialState = {
  all: {
    reports: [],
  },
  byId: {},
  comments: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REPORTS_LOAD_ALL_REQUEST: {
      return {
        ...state,
        all: {
          ...state.all,
          isLoading: true,
        },
      };
    }
    case actionTypes.REPORTS_REVERSE_APPROVE_SUCCESS: {
      const { reports } = action.payload;
      return {
        ...state,
        all: {
          reports,
          isLoading: false,
        },
      };
    }
    case actionTypes.REPORTS_LOAD_ALL_SUCCESS: {
      const { reports } = action.payload;
      return {
        ...state,
        all: {
          reports,
          isLoading: false,
        },
      };
    }
    case actionTypes.REPORTS_LOAD_ALL_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        all: {
          reports: [],
          isLoading: false,
          error,
        },
      };
    }
    case actionTypes.REPORTS_LOAD_REQUEST: {
      const { reportId } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [reportId]: {
            report: R.pathOr(undefined, [reportId, 'report'], state.byId),
            isLoading: true,
          },
        },
      };
    }
    case actionTypes.REPORTS_LOAD_SUCCESS: {
      const { report } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [report.id]: {
            report,
            isLoading: false,
          },
        },
      };
    }
    case actionTypes.REPORTS_LOAD_FAILURE: {
      const {
        error,
        options: { reportId },
      } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [reportId]: {
            isLoading: false,
            error,
          },
        },
      };
    }
    case actionTypes.REPORTS_LOAD_COMMENTS_REQUEST: {
      const { reportId } = action.payload;
      return {
        ...state,
        comments: {
          ...state.comments,
          [reportId]: {
            comments: R.pathOr([], [reportId, 'comments'], state.comments),
            isLoading: true,
          },
        },
      };
    }
    case actionTypes.REPORTS_LOAD_COMMENTS_SUCCESS: {
      const { reportId, comments } = action.payload;
      return {
        ...state,
        comments: {
          ...state.comments,
          [reportId]: {
            comments: R.map(comment => ({ comment }), comments),
            isLoading: false,
          },
        },
      };
    }
    case actionTypes.REPORTS_LOAD_COMMENTS_FAILURE: {
      const {
        error,
        options: { reportId },
      } = action.payload;
      return {
        ...state,
        comments: {
          ...state.comments,
          [reportId]: {
            isLoading: false,
            error,
          },
        },
      };
    }
    case actionTypes.REPORTS_ADD_COMMENT: {
      const { reportId, title, body } = action.payload;
      return {
        ...state,
        comments: {
          ...state.comments,
          [reportId]: {
            comments: R.append(
              {
                comment: {
                  reportId,
                  title,
                  body,
                },
              },
              R.pathOr([], [reportId, 'comments'], state.comments)
            ),
          },
        },
      };
    }
    case actionTypes.REPORTS_SAVE_COMMENT_REQUEST:
    case actionTypes.REPORTS_FINALIZE_COMMENT_REQUEST: {
      const { reportId, commentId, commentsUpdatedAt, sequence, title, body } = action.payload;
      const comment = { reportId, id: commentId, commentsUpdatedAt, sequence, title, body };
      const predicateFn = R.isNil(commentId) ? R.isNil : R.equals(commentId);
      return {
        ...state,
        comments: {
          ...state.comments,
          [reportId]: {
            comments: R.map(
              R.when(R.pathSatisfies(predicateFn, ['comment', 'id']), R.always({ comment, isSaving: true })),
              R.pathOr([], [reportId, 'comments'], state.comments)
            ),
          },
        },
      };
    }
    case actionTypes.REPORTS_CREATE_COMMENT_SUCCESS: {
      const { reportId, commentId, sequence, title, body } = action.payload;
      const comment = { reportId, id: commentId, sequence, title, body };
      return {
        ...state,
        comments: {
          ...state.comments,
          [reportId]: {
            comments: R.map(
              R.when(R.pathSatisfies(R.isNil, ['comment', 'id']), R.always({ comment, isSaving: false })),
              R.pathOr([], [reportId, 'comments'], state.comments)
            ),
          },
        },
      };
    }
    case actionTypes.REPORTS_UPDATE_COMMENT_SUCCESS: {
      const { reportId, commentId, commentsUpdatedAt, sequence, title, body } = action.payload;
      const comment = { reportId, id: commentId, commentsUpdatedAt, sequence, title, body };
      return {
        ...state,
        comments: {
          ...state.comments,
          [reportId]: {
            comments: R.map(
              R.when(R.pathSatisfies(R.equals(commentId), ['comment', 'id']), R.always({ comment, isSaving: false })),
              R.pathOr([], [reportId, 'comments'], state.comments)
            ),
          },
        },
      };
    }
    case actionTypes.REPORTS_SAVE_COMMENT_FAILURE: {
      const {
        error,
        options: { reportId, commentId },
      } = action.payload;
      const predicateFn = R.isNil(commentId) ? R.isNil : R.equals(commentId);
      return {
        ...state,
        comments: {
          ...state.comments,
          [reportId]: {
            comments: R.map(
              R.when(
                R.pathSatisfies(predicateFn, ['comment', 'id']),
                R.compose(R.assoc('isSaving', false), R.assoc('error', error))
              ),
              R.pathOr([], [reportId, 'comments'], state.comments)
            ),
          },
        },
      };
    }
    case actionTypes.REPORTS_DELETE_COMMENT_SUCCESS: {
      const { reportId, commentId } = action.payload;
      return {
        ...state,
        comments: {
          ...state.comments,
          [reportId]: {
            comments: R.filter(
              R.complement(R.pathEq(['comment', 'id'], commentId)),
              R.pathOr([], [reportId, 'comments'], state.comments)
            ),
          },
        },
      };
    }
    case actionTypes.REPORTS_UPDATE_COMMENT_SEQUENCE_SUCCESS: {
      const { reportId, commentId, sequence } = action.payload;
      const comment = R.find(
        R.pathEq(['comment', 'id'], commentId),
        R.pathOr([], [reportId, 'comments'], state.comments)
      );
      return {
        ...state,
        comments: {
          ...state.comments,
          [reportId]: {
            comments: R.insert(
              sequence,
              comment,
              R.filter(
                R.complement(R.pathEq(['comment', 'id'], commentId)),
                R.pathOr([], [reportId, 'comments'], state.comments)
              )
            ),
          },
        },
      };
    }
    case actionTypes.REPORTS_UPDATE_EXECUTIVE_SUMMARY_REQUEST: {
      const { reportId, executiveSummary, executiveSummaryUpdatedAt } = action.payload;
      return {
        ...state,
        byId: {
          [reportId]: {
            report: R.assoc(
              'executiveSummary',
              executiveSummary,
              R.assoc(
                'executiveSummaryUpdatedAt',
                executiveSummaryUpdatedAt,
                R.pathOr({}, [reportId, 'report'], state.byId)
              )
            ),
            isSavingExecutiveSummary: true,
          },
        },
      };
    }
    case actionTypes.REPORTS_UPDATE_EXECUTIVE_SUMMARY_SUCCESS: {
      const { reportId, executiveSummary, executiveSummaryUpdatedAt } = action.payload;
      return {
        ...state,
        byId: {
          [reportId]: {
            report: R.assoc(
              'executiveSummary',
              executiveSummary,
              R.assoc(
                'executiveSummaryUpdatedAt',
                executiveSummaryUpdatedAt,
                R.pathOr({}, [reportId, 'report'], state.byId)
              )
            ),
            isSavingExecutiveSummary: false,
          },
        },
      };
    }
    case actionTypes.REPORTS_UPDATE_EXECUTIVE_SUMMARY_FAILURE: {
      const {
        error,
        options: { reportId },
      } = action.payload;
      return {
        ...state,
        byId: {
          [reportId]: {
            ...R.propOr({}, reportId, state.byId),
            isSavingExecutiveSummary: false,
            error,
          },
        },
      };
    }
    case actionTypes.REPORTS_APPROVE_SUCCESS: {
      const { report } = action.payload;
      return {
        ...state,
        all: {
          ...state.all,
          reports: R.filter(R.complement(R.propEq('id', report.id)), state.all.reports),
        },
      };
    }
    case actionTypes.REPORTS_REJECT_SUCCESS: {
      const { report } = action.payload;
      return {
        ...state,
        all: {
          ...state.all,
          reports: R.filter(R.complement(R.propEq('id', report.id)), state.all.reports),
        },
      };
    }
    case actionTypes.REPORTS_CLOSE_SUCCESS: {
      const { report } = action.payload;
      return {
        ...state,
        all: {
          ...state.all,
          reports: R.filter(R.complement(R.propEq('id', report.id)), state.all.reports),
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
