import * as R from 'ramda';
import * as actionTypes from '../action-types';
import quarterKey from './quarter-key';

/*
{
  byQuarter: {
    [elementId]: {
      [year]: {
        [quarter]: {
          attachments: [
            {
              attachment,
              temporaryId,
              isLoading,
              error,
            }
          ],
          isLoading,
          error,
        }
      }
    }
  },
  byElementId: {
    [elementId]: {
      attachments: [],
      isLoading,
      error,
    }
  }
}
*/

const toLoadedAttachmentState = attachment => ({
  attachment,
});

const toTemporaryAttachmentState = (elementId, year, quarter, fileType, file, temporaryId) => ({
  attachment: {
    elementId,
    year,
    quarter,
    type: fileType,
    filename: file.name,
    mimetype: file.type,
    size: file.size,
  },
  temporaryId,
  isLoading: true,
});

const toSavedAttachmentState = savedAttachment => () => ({
  attachment: savedAttachment,
  isLoading: false,
});

const toDeletingAttachmentState = attachment => ({
  attachment: {
    ...attachment.attachment,
  },
  isLoading: true,
});

const toErrorDeletingAttachmentState = R.curry((error, attachment) => ({
  attachment: {
    ...attachment.attachment,
  },
  isLoading: false,
  error,
}));

const initialState = {
  byQuarter: {},
  byElementId: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REPORT_ATTACHMENTS_LOAD_REQUEST: {
      const { elementId } = action.payload;
      return {
        ...state,
        byElementId: {
          ...state.byElementId,
          [elementId]: {
            ...R.propOr({}, elementId, state.byElementId),
            isLoading: true,
          },
        },
      };
    }
    case actionTypes.REPORT_ATTACHMENTS_LOAD_SUCCESS: {
      const { elementId, attachments } = action.payload;
      return {
        ...state,
        byElementId: {
          ...state.byElementId,
          [elementId]: {
            attachments,
            isLoading: false,
          },
        },
      };
    }
    case actionTypes.REPORT_ATTACHMENTS_LOAD_FAILURE: {
      const {
        error,
        options: { elementId },
      } = action.payload;
      return {
        ...state,
        byElementId: {
          ...state.byElementId,
          [elementId]: {
            ...R.propOr({}, elementId, state.byElementId),
            isLoading: false,
            error,
          },
        },
      };
    }
    case actionTypes.REPORT_ATTACHMENTS_LOAD_BY_QUARTER_REQUEST: {
      const { elementId, year, quarter } = action.payload;
      const key = quarterKey(elementId, year, quarter);
      return {
        ...state,
        byQuarter: {
          ...state.byQuarter,
          [key]: {
            attachments: [],
            isLoading: true,
          },
        },
      };
    }
    case actionTypes.REPORT_ATTACHMENTS_LOAD_BY_QUARTER_SUCCESS: {
      const { elementId, year, quarter, attachments } = action.payload;
      const key = quarterKey(elementId, year, quarter);
      return {
        ...state,
        byQuarter: {
          ...state.byQuarter,
          [key]: {
            attachments: R.map(toLoadedAttachmentState, attachments),
            isLoading: false,
          },
        },
      };
    }
    case actionTypes.REPORT_ATTACHMENTS_LOAD_BY_QUARTER_FAILURE: {
      const {
        error,
        options: { elementId, year, quarter },
      } = action.payload;
      const key = quarterKey(elementId, year, quarter);
      return {
        ...state,
        byQuarter: {
          ...state.byQuarter,
          [key]: {
            ...R.propOr({}, key, state.byQuarter),
            isLoading: false,
            error,
          },
        },
      };
    }
    case actionTypes.REPORT_ATTACHMENT_ADD_REQUEST: {
      const { elementId, year, quarter, temporaryId, fileType, file } = action.payload;
      const key = quarterKey(elementId, year, quarter);
      return {
        ...state,
        byQuarter: {
          ...state.byQuarter,
          [key]: {
            attachments: R.append(
              toTemporaryAttachmentState(elementId, year, quarter, fileType, file, temporaryId),
              R.pathOr([], [key, 'attachments'], state.byQuarter)
            ),
          },
        },
      };
    }
    case actionTypes.REPORT_ATTACHMENT_ADD_SUCCESS: {
      const { attachment, temporaryId } = action.payload;
      const key = quarterKey(attachment.elementId, attachment.year, attachment.quarter);
      return {
        ...state,
        byQuarter: {
          ...state.byQuarter,
          [key]: {
            attachments: R.map(
              R.when(R.propEq('temporaryId', temporaryId), toSavedAttachmentState(attachment)),
              R.pathOr([], [key, 'attachments'], state.byQuarter)
            ),
            isLoading: false,
          },
        },
        byElementId: {
          ...state.byElementId,
          [attachment.elementId]: {
            attachments: R.append(attachment, R.pathOr([], [attachment.elementId, 'attachments'], state.byElementId)),
          },
        },
      };
    }
    case actionTypes.REPORT_ATTACHMENT_ADD_FAILURE: {
      const {
        error,
        options: { elementId, year, quarter, temporaryId },
      } = action.payload;
      const key = quarterKey(elementId, year, quarter);
      return {
        ...state,
        byQuarter: {
          ...state.byQuarter,
          [key]: {
            attachments: R.reject(
              R.propEq('temporaryId', temporaryId),
              R.pathOr([], [key, 'attachments'], state.byQuarter)
            ),
            isLoading: false,
            error,
          },
        },
      };
    }
    case actionTypes.REPORT_ATTACHMENT_DELETE_REQUEST: {
      const { elementId, year, quarter, attachmentId } = action.payload;
      const key = quarterKey(elementId, year, quarter);
      return {
        ...state,
        byQuarter: {
          ...state.byQuarter,
          [key]: {
            attachments: R.map(
              R.when(R.pathEq(['attachment', 'id'], attachmentId), toDeletingAttachmentState),
              R.pathOr([], [key, 'attachments'], state.byQuarter)
            ),
          },
        },
      };
    }
    case actionTypes.REPORT_ATTACHMENT_DELETE_SUCCESS: {
      const { elementId, year, quarter, attachmentId } = action.payload;
      const key = quarterKey(elementId, year, quarter);
      return {
        ...state,
        byQuarter: {
          ...state.byQuarter,
          [key]: {
            attachments: R.reject(
              R.pathEq(['attachment', 'id'], attachmentId),
              R.pathOr([], [key, 'attachments'], state.byQuarter)
            ),
          },
        },
      };
    }
    case actionTypes.REPORT_ATTACHMENT_DELETE_FAILURE: {
      const {
        error,
        options: { elementId, year, quarter, attachmentId },
      } = action.payload;
      const key = quarterKey(elementId, year, quarter);
      return {
        ...state,
        byQuarter: {
          ...state.byQuarter,
          [key]: {
            attachments: R.map(
              R.when(R.pathEq(['attachment', 'id'], attachmentId), toErrorDeletingAttachmentState(error)),
              R.pathOr([], [key, 'attachments'], state.byQuarter)
            ),
          },
        },
      };
    }
    default:
      return state;
  }
};
