import React from 'react';
import styled from 'styled-components';
import UnstyledCircularProgress from '@material-ui/core/CircularProgress';
import * as R from 'ramda';
import ScorecardTable from '../Scorecard/ScorecardTable';
import { Report } from '../../hooks/report';
import useScorecard from '../../hooks/scorecard';
import { ScorecardSectionsProvider } from '../../hooks/scorecard-sections';
import TotalScore from '../Scorecard/TotalScore';

const CircularProgress = styled(UnstyledCircularProgress)``;

const Container = styled.div`
  ${CircularProgress} {
    margin-top: 20px;
  }
`;

interface SummaryScorecardProps {
  className?: string;
  report: Report;
}

const SummaryScorecard: React.FC<SummaryScorecardProps> = ({ className, report }) => {
  const { scorecard, isLoading } = useScorecard(report.elementId, Number(report.year), Number(report.quarter));

  return (
    <Container className={className}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <ScorecardSectionsProvider elementId={report.elementId}>
          {!R.isEmpty(scorecard) && (
            <>
              <ScorecardTable scorecard={scorecard} />
              <TotalScore
                report={report}
                scorecard={scorecard}
                onClick={null}
              />
            </>
          )}
        </ScorecardSectionsProvider>
      )}
    </Container>
  );
};

export default SummaryScorecard;
