import authProvider from '../auth/authProvider';

import actions from './actions';
import colourValues from './colour-values';
import conditionReports from './condition-reports';
import createServer from './server';
import cwt from './cwt';
import dashboards from './dashboards';
import elementAttachmentTypes from './element-attachment-types';
import elements from './elements';
import facilities from './facilities';
import indicatorRecords from './indicators/indicator-records';
import indicators from './indicators';
import my from './my';
import pms from './pms';
import referenceTypes from './reference-types';
import reports from './reports';
import scorecardColours from './scorecard-colours';
import summary from './summary';
import tcc from './tcc';
import totalScoreColours from './total-score-colours';
import units from './units';
import users from './users';
import versions from './versions';
import workOrders from './work-orders';
import workRequests from './work-requests';
import utility from './utility';
import historicalReports from './historical-reports';
import mcrUnits from './mcr-units';
import deferrals from './deferrals';
import walkdownTemplates from './walkdown-templates';
import walkdowns from './walkdowns';
import adminCategories from './admin-categories';
import riskManagement from './risk-management';
import outages from './outages';

const ECHO_SERVER_BASE = process.env.REACT_APP_ECHO_SERVER_BASE || '';

const v1BaseUrl = `${ECHO_SERVER_BASE}/api/v1`;

const v1AuthPath =
  basePath =>
  (path = '') =>
    `${v1BaseUrl}/auth${basePath}${path}`;

const server = createServer(authProvider);

export default {
  ...actions(v1AuthPath('/actions'), server),
  ...colourValues(v1AuthPath('/colour-values'), server),
  ...conditionReports(v1AuthPath('/condition-reports'), server),
  ...cwt(v1AuthPath('/cwt'), server),
  ...dashboards(v1AuthPath('/dashboards'), server),
  ...deferrals(v1AuthPath('/deferrals'), server),
  ...elements(v1AuthPath('/elements'), server),
  ...elementAttachmentTypes(v1AuthPath('/element-attachment-types'), server),
  ...facilities(v1AuthPath('/facilities'), server),
  ...indicatorRecords(v1AuthPath('/indicator-records'), server),
  ...indicators(v1AuthPath('/indicators'), server),
  ...my(v1AuthPath('/my'), server),
  ...mcrUnits(v1AuthPath('/mcr-units'), server),
  ...outages(v1AuthPath('/outages'), server),
  ...pms(v1AuthPath('/pms'), server),
  ...referenceTypes(v1AuthPath('/reference-types'), server),
  ...scorecardColours(v1AuthPath('/scorecard-colours'), server),
  ...summary(v1AuthPath('/summary'), server),
  ...reports(v1AuthPath('/reports'), server),
  ...tcc(v1AuthPath('/tcc'), server),
  ...totalScoreColours(v1AuthPath('/total-score-colours'), server),
  ...users(v1AuthPath('/users'), server),
  ...units(v1AuthPath('/units'), server),
  ...versions(v1AuthPath('/versions'), server),
  ...workOrders(v1AuthPath('/work-orders'), server),
  ...workRequests(v1AuthPath('/work-requests'), server),
  ...utility(v1AuthPath('/utility'), server),
  ...historicalReports(v1AuthPath('/archive-reports'), server),
  ...walkdownTemplates(v1AuthPath('/walkdowns/templates'), server),
  ...walkdowns(v1AuthPath('/walkdowns'), server),
  ...adminCategories(v1AuthPath('/admin-categories'), server),
  ...riskManagement(v1AuthPath('/risk-management'), server),
};
