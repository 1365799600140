import React from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import CustomRadioGroup, { RadioOption } from '../components/CustomRadioGroup';
import { identity } from 'lodash';

// TODO-RS: Improve typing of toFormValue & fromFormValue props
export type ControlRadioProps<T extends FieldValues> = {
  control: Control<T>;
  required?: boolean;
  label: string;
  name: Path<T>;
  options: RadioOption[];
  error: boolean;
  row?: boolean;
  disabled?: boolean;
  toFormValue?: (value: any) => string;
  fromFormValue?: (value: string) => any;
};

export const ControlRadio = <T extends FieldValues>({
  control,
  name,
  error,
  label,
  options,
  row,
  required,
  disabled = false,
  toFormValue = identity,
  fromFormValue = identity,
}: ControlRadioProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      disabled={disabled}
      render={({ field }) => (
        <CustomRadioGroup
          options={options}
          onChange={(_, value) => field.onChange(fromFormValue(value))}
          value={toFormValue(field.value)}
          disabled={field.disabled}
          error={error}
          helperText={label}
          helperTextPosition="top"
          font="500 16px Roboto"
          labelColor="#4a4a4a"
          row={row}
        />
      )}
    />
  );
};
