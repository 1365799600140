import React, { useState } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import {
  Button,
  Grid,
  TextField,
  List as UnstyledList,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
  Card,
  CardContent,
} from '@material-ui/core';

import useOverrideDetails, { OverrideDetailsProvider } from '../../hooks/override-details';
import InvalidIndicatorDetails from '../../pages/live-reports/tabs/review-records/components/RecordDetailsPanel/InvalidIndicatorDetails';
import { recordDetailsComponentMap } from '../../pages/live-reports/tabs/review-records/components/RecordDetailsPanel/RecordDetails';
import useRecordDetails from '../../pages/live-reports/tabs/review-records/hooks/record-details';

const List = styled(UnstyledList)`
  overflow: 'auto';
  maxheight: 300;
  border: 1px solid black;
`;

const RecordDetails = ({ shortName, record }: any) => {
  const { recordDetails } = useRecordDetails(record, shortName);
  const RecordDetailsComponent: any = R.propOr(InvalidIndicatorDetails, shortName, recordDetailsComponentMap);

  return (
    <RecordDetailsComponent
      recordDetails={recordDetails}
      dense
    />
  );
};

const OverrideDetails = () => {
  const { records, shortName, assign, searchRecords } = useOverrideDetails();
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const [search, setSearch] = useState<any>(null);

  const handleOnApply = () => {
    assign(selectedRecord);
    setSelectedRecord(null);
    setSearch(null);
  };

  const handleOnBlur = (e: any) => {
    setSearch(e.target.value);
  };

  const handleSearch = () => {
    searchRecords(search);
  };

  return (
    <Grid
      container
      spacing={2}
      style={{ marginTop: '2%' }}
    >
      <Grid
        xs={12}
        item
        container
        alignContent="center"
        justifyContent="space-between"
      >
        <Grid
          item
          spacing={2}
          container
          alignContent="center"
          xs={10}
        >
          <Grid
            xs={3}
            item
          >
            <TextField
              id="outlined-basic"
              label="Search Record #"
              variant="outlined"
              onBlur={handleOnBlur}
            />
          </Grid>
          <Grid
            xs={7}
            item
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleSearch}
            >
              Search
            </Button>
          </Grid>
        </Grid>
        {!R.isNil(selectedRecord) && (
          <Grid
            item
            xs={1}
            style={{ marginRight: '2%' }}
          >
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleOnApply}
            >
              Apply
            </Button>
          </Grid>
        )}
      </Grid>
      {records && records.length === 0 ? (
        <Grid
          xs={12}
          item
        >
          <Typography>No Records</Typography>
        </Grid>
      ) : (
        <>
          <Grid
            xs={12}
            item
          >
            {records.length > 0 && (
              <List
                subheader={
                  <ListSubheader
                    disableSticky
                    component="div"
                    id="nested-list-subheader"
                  >
                    Results
                  </ListSubheader>
                }
              >
                {records.map((record: any) => (
                  <ListItem
                    key={record.usi}
                    button
                    onClick={() => setSelectedRecord(record)}
                  >
                    <ListItemText primary={record.title || record.description} />
                  </ListItem>
                ))}
              </List>
            )}
          </Grid>
          {!R.isNil(selectedRecord) && (
            <Grid
              xs={12}
              item
            >
              <Card>
                <CardContent>
                  <Grid
                    xs={12}
                    item
                  >
                    <RecordDetails
                      shortName={shortName}
                      record={selectedRecord}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

interface ConnectorProps {
  indicatorName: string;
  indicatorId: number;
  facilityId: string;
  elementUnit: string;
  elementType?: string;
  elementId: number;
  year: number;
  quarter: number;
}

export default ({
  indicatorName,
  indicatorId,
  facilityId,
  elementUnit,
  elementType,
  elementId,
  year,
  quarter,
}: ConnectorProps) => (
  <OverrideDetailsProvider
    indicatorName={indicatorName}
    indicatorId={indicatorId}
    facilityId={facilityId}
    elementId={elementId}
    elementUnit={elementUnit}
    elementType={elementType}
    year={year}
    quarter={quarter}
  >
    <OverrideDetails />
  </OverrideDetailsProvider>
);
