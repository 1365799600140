import * as R from 'ramda';
import { recordEqualsFn } from '../../utils/indicator-specifics';
import * as actionTypes from '../action-types';
import { recordKey, quarterKey } from './keys';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INDICATOR_RECORDS_LOAD_REQUEST: {
      const { elementId, indicatorId } = action.payload;
      const key = recordKey(elementId, indicatorId);
      return {
        ...state,
        [key]: {
          ...state[key],
          isLoading: true,
          error: undefined,
        },
      };
    }
    case actionTypes.INDICATOR_RECORDS_LOAD_SUCCESS: {
      const { elementId, indicatorId, records } = action.payload;
      return {
        ...state,
        [recordKey(elementId, indicatorId)]: {
          records,
          isLoading: false,
        },
      };
    }
    case actionTypes.INDICATOR_RECORDS_LOAD_FAILURE: {
      const {
        error,
        options: { elementId, indicatorId },
      } = action.payload;
      const key = recordKey(elementId, indicatorId);
      return {
        ...state,
        [key]: {
          ...state[key],
          isLoading: false,
          error,
        },
      };
    }
    case actionTypes.INDICATOR_RECORDS_LOAD_BY_QUARTER_REQUEST: {
      const { elementId, year, quarter, indicatorId } = action.payload;
      const key = quarterKey(elementId, year, quarter, indicatorId);
      return {
        ...state,
        [key]: {
          ...state[key],
          isLoading: true,
          error: undefined,
        },
      };
    }
    case actionTypes.INDICATOR_RECORDS_LOAD_BY_QUARTER_SUCCESS: {
      const { elementId, year, quarter, indicatorId, records } = action.payload;
      return {
        ...state,
        [quarterKey(elementId, year, quarter, indicatorId)]: {
          records,
          isLoading: false,
        },
      };
    }
    case actionTypes.INDICATOR_RECORDS_LOAD_BY_QUARTER_FAILURE: {
      const {
        error,
        options: { elementId, year, quarter, indicatorId },
      } = action.payload;
      const key = quarterKey(elementId, year, quarter, indicatorId);
      return {
        ...state,
        [key]: {
          ...state[key],
          isLoading: false,
          error,
        },
      };
    }
    case actionTypes.INDICATOR_RECORDS_UPDATE_APPLICABILITY_SUCCESS: {
      const { elementId, year, quarter, indicatorId, indicatorShortName, recordId, applicable, options } =
        action.payload;
      const key =
        R.isNil(year) && R.isNil(quarter)
          ? recordKey(elementId, indicatorId)
          : quarterKey(elementId, year, quarter, indicatorId);
      const propEqFn = recordEqualsFn(indicatorShortName);
      const isApplicable = options.recordType ? indicatorShortName === options.recordType : applicable;
      return {
        ...state,
        [key]: {
          ...R.propOr({}, key, state),
          records: R.map(
            R.when(
              propEqFn(recordId),
              R.mergeLeft({
                applicable: isApplicable,
                ...options,
              })
            ),
            R.pathOr([], [key, 'records'], state)
          ),
        },
      };
    }
    default:
      return state;
  }
};
