import React from 'react';
import styled from 'styled-components';
import { AllColourValuesProvider } from '../../../../hooks/all-colour-values';
import ChartContainer from './ChartContainer';

const Container = styled.div`
  padding: 50px;
`;

const ChartPanel = ({ className, elementId }) => (
  <Container className={className}>
    <AllColourValuesProvider>
      <ChartContainer elementId={elementId} />
    </AllColourValuesProvider>
  </Container>
);

export default ChartPanel;
