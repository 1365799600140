/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import * as R from 'ramda';
import Grid from '@material-ui/core/Grid';
import useForm from '../../hooks/form';
import useConditionalConfirm from '../../hooks/conditional-confirm';
import FormDialog from '../FormDialog';
import OkayCancelDialog from '../OkayCancelDialog';
import ColourSelect from '../ColourSelect';
import CustomTextField from '../CustomTextField';
import ActionButton from '../ActionButton';

interface ManualValue {
  value: number;
  colour: string;
  comment: string;
}

interface Props {
  title?: string;
  validation?: any;
  manualValue: ManualValue;
  readonly?: boolean;
  onSave: (value: number, colour: string, comment: string) => void;
  onCancel: () => void;
  onClear?: () => void;
}

const formConfig = (validation: any) => ({
  value: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Enter a value',
      },
    },
  },
  colour: {
    validate: {
      presence: {
        allowEmpty: false,
        message: 'Select a colour',
      },
    },
  },
  comment: {},
  ...validation,
});

const initialValues = (manualValue: ManualValue) => ({
  value: manualValue.value,
  colour: manualValue.colour,
  comment: manualValue.comment,
});

const ManualValueDialog = ({
  title = 'Manual Indicator Value',
  validation = {},
  manualValue,
  readonly,
  onSave,
  onCancel,
  onClear,
}: Props) => {
  const handleSave = (values: ManualValue) => {
    onSave(values.value, values.colour, values.comment);
  };

  const { values, submit, isDirty, propsForField } = useForm(
    formConfig(validation),
    initialValues(manualValue),
    handleSave
  );
  const {
    confirming,
    confirm: confirmCancel,
    onConfirm: onConfirmCancel,
    onCancel: onCancelConfirm,
  } = useConditionalConfirm(onCancel, isDirty);

  const canSave = !R.isNil(values.colour) || !readonly;

  return (
    <FormDialog
      title={title}
      hideSave={!canSave || readonly}
      onSave={submit}
      onCancel={confirmCancel}
    >
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <CustomTextField
            label="Value"
            fullWidth
            {...propsForField('value')}
            disabled={readonly}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <ColourSelect
            label="Colour"
            fullWidth
            {...propsForField('colour')}
            disabled={readonly}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <CustomTextField
            label="Comment"
            fullWidth
            inputProps={{ maxLength: 255 }}
            multiline
            {...propsForField('comment')}
            disabled={readonly}
          />
        </Grid>
        {onClear && (
          <Grid
            item
            xs={12}
          >
            <ActionButton
              onClick={onClear}
              color="secondary"
            >
              Clear Override
            </ActionButton>
          </Grid>
        )}
      </Grid>
      {confirming && (
        <OkayCancelDialog
          title="Cancel?"
          okayLabel="Continue"
          onOkay={onConfirmCancel}
          onCancel={onCancelConfirm}
        >
          Are you sure? If you choose to continue, you will lose the data you have entered.
        </OkayCancelDialog>
      )}
    </FormDialog>
  );
};

export default ManualValueDialog;
