import ActionButton from '../../../../components/ActionButton';
import Box from '@material-ui/core/Box';
import GeneralInfoForm from './GeneralInfoForm';
import { EquipmentPerformanceInformation } from './EquipmentPerformanceInformation';
import CurrentStatusInfo from './CurrentStatusInfo';
import { ImpactProbabilityAssessment } from './ImpactProbabilityAssessment';
import ShipChipSection from './ShipChip';
import useRiskManagement from '../../../../context/risk-management';
import UserRiskRolesDialog from '../../../admin/components/BusinessEquipmentRisk/UserRiskRolesDialog';
import { WrappedInput } from './WrappedInput';
import { ControlTextField } from '../../../../form/ControlTextField';
import { type FormEventHandler, useEffect, useState } from 'react';
import api from '../../../../api';

export const RiskOwnerForm = () => {
  const { riskOwnerForm, riskApprovers, data, onOpportunityOrThreatChange, impactAssessments } = useRiskManagement();
  if (!riskOwnerForm) {
    return null;
  }
  const {
    riskStatus,
    handleSubmit,
    setSubmitting,
    submitting,
    saveDraft,
    loading,
    control,
    errors,
    watchSpv,
    watchElementId,
    watchOpportunityOrThreat,
    radioOptions,
    watchContingencyPlanningStatus,
    watchBridgingStrategy,
    watchCritical,
    watchObsolescence,
    watchRiskCategory,
    watchClimateChange,
    watchEnv,
    watchHealthScore,
    watchHealthScoreColor,
    unitsOptions,
    riskCategories,
    interestedParties,
    watchPotentialOverlife,
    isReadOnly,
    rsaArcherRskRecordReference,
    riskOwner,
    notifyRiskApprovers,
    onCloseNotifyRiskApprovers,
    onNotifyRiskApproversSubmit,
    engineeringPerformanceOptions,
    setValue,
  } = riskOwnerForm;

  const [ongoingActions, setOngoing] = useState([]);
  const [completedActions, setCompleted] = useState([]);

  useEffect(() => {
    // this is to prevent loading the element multiple times, has to do with scorecard
    setOngoing([]);
    setCompleted([]);
    if (watchElementId) {
      api.getActions(watchElementId, 'ongoing').then((res: any) => setOngoing(res));
      api.getActions(watchElementId, 'complete').then((res: any) => setCompleted(res));
    }
  }, [watchElementId]);

  // TODO: Adding this within the component due to rerender "lag" issues between the form/component/hooks when the shipchip list is very large, which results in significant delay before running the actual handleSubmit() function and means that the user has ample time to button mash in the meantime. Switch when the render issue is fixed.
  // Note: If this code becomes more permanent, switch to using a throttle function like https://lodash.com/docs/4.17.15#throttle
  const submitHandler: FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();
    if (!submitting) {
      setSubmitting(true);
      await handleSubmit();
    }
  };

  return (
    <Box padding="40px">
      <form onSubmit={submitHandler}>
        <GeneralInfoForm
          riskStatus={data.status}
          control={control}
          errors={errors}
          isReadOnly={isReadOnly}
          rsaArcherRskRecordReference={rsaArcherRskRecordReference}
          riskOwner={riskOwner}
          watchElementId={watchElementId}
          watchHealthScore={watchHealthScore}
          loadHealthScore={Boolean(ongoingActions.length || ongoingActions.length) && !isReadOnly}
          watchHealthScoreColor={watchHealthScoreColor}
          setValue={setValue}
          onOpportunityOrThreatChange={onOpportunityOrThreatChange}
        />
        <Box
          marginTop="40px"
          marginBottom="40px"
        >
          <CurrentStatusInfo
            control={control}
            errors={errors}
            watchContingencyPlanningStatus={watchContingencyPlanningStatus}
            watchClimateChange={watchClimateChange}
            unitsOptions={unitsOptions}
            watchRiskCategory={watchRiskCategory}
            riskCategories={riskCategories}
            interestedParties={interestedParties}
            isReadOnly={isReadOnly}
          />
        </Box>
        <EquipmentPerformanceInformation
          control={control}
          errors={errors}
          radioOptions={radioOptions}
          watchSpv={watchSpv}
          isReadOnly={isReadOnly}
          watchPotentialOverlife={watchPotentialOverlife}
          watchBridgingStrategy={watchBridgingStrategy}
          watchCritical={watchCritical}
          watchObsolescence={watchObsolescence}
          engineeringPerformanceOptions={engineeringPerformanceOptions}
        />
        {watchOpportunityOrThreat ? (
          <Box marginTop="40px">
            <ImpactProbabilityAssessment
              watchOpportunityOrThreat={watchOpportunityOrThreat}
              isReadOnly={isReadOnly}
            />
          </Box>
        ) : null}
        {watchElementId ? (
          <Box marginTop="40px">
            <ShipChipSection
              elementId={watchElementId}
              riskManagementId={data.riskId}
              isReadOnly={isReadOnly}
            />
          </Box>
        ) : null}
        <Box marginTop="40px">
          <WrappedInput>
            <ControlTextField
              control={control}
              name="recommendedDecisionType"
              label="Recommend a decision type to the approver"
              minLength={0}
              maxLength={1000}
              placeholder="Optional (Character Limitation: 1000 characters)"
              required={false}
              error={!!errors.recommendedDecisionType}
              disabled={isReadOnly}
            />
          </WrappedInput>
        </Box>
        {(riskStatus === 'DRAFT' || !riskStatus) && !!watchElementId && (
          <Box marginTop="40px">
            <ActionButton onClick={saveDraft}>Save Draft</ActionButton>
          </Box>
        )}
        <Box marginTop="20px">
          <ActionButton
            color="primary"
            type="submit"
            aria-label="submit"
            disabled={
              isReadOnly ||
              impactAssessments.length === 0 ||
              (!ongoingActions.length && !data.prevDecisionType) ||
              (!ongoingActions.length && !completedActions.length)
            }
          >
            Submit
          </ActionButton>
        </Box>
      </form>
      {notifyRiskApprovers && (
        <UserRiskRolesDialog
          users={riskApprovers}
          onSubmit={onNotifyRiskApproversSubmit(watchEnv)}
          onClose={onCloseNotifyRiskApprovers}
          title="Choose Approvers to Notify"
          multiple={true}
          loading={loading}
        />
      )}
    </Box>
  );
};
