import { Control, FieldErrors, SubmitHandler, useForm, UseFormSetValue } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import api from '../../../../api';
import { InterestedParties, RiskCategories, RiskOwnerFormInputs } from '../../../../types/risk-management';
import elementTypePrefix from '../../../../utils/element-type-prefix';
import { useHistory, useLocation } from 'react-router-dom';
import * as uiActions from '../../../../state/ui/actions';
import { useDispatch } from 'react-redux';
import { OutagePlan } from '../../../../types/outage-plans';
import { BusinessObjective } from '../../../../types/business-objectives';
import { MSMProgram } from '../../../../types/msm-programs';
import { MyElement } from '../../../../types/my';
import authProvider from '../../../../auth/authProvider';
import useUsers from '../../../../hooks/users';
import { User } from '../../../../types/user';
import { getUnitsForFacility, Unit } from '../../../../utils/units';
import { AutocompleteOption } from '../../../../components/AutocompleteInput';
import { RadioOption } from '../../../../components/CustomRadioGroup';

const ENGINEERING_PERFORMANCE_CATEGORIES = {
  assessingAndDocs: 'Assessing and Docs',
  baPlantEngineering: 'BA Plant Engineering',
  balanceOfPlant: 'Balance of Plant',
  bbPlantEngineering: 'BB Plant Engineering',
  boilersAndReactorMaintenance: 'Boilers and Reactor Maintenance',
  centralMaintenance: 'Central Maintenance',
  civilSecurityDesign: 'Civil / Security Design',
  componentDesignElectrical: 'Component Design Electrical/I&C',
  componentDesignMechanical: 'Component Design Mechanical',
  computerEngineering: 'Computer Engineering',
  controlDesign: 'Control Design',
  correctiveAndDefective: 'Corrective and Defective',
  cranesAndSgs: 'Cranes and SGs',
  dccReplacement: 'DCC Replacement',
  designPrograms: 'Design Programs',
  designServices: 'Design Services',
  deterministicSafetyMoment: 'Deterministic Safety Support',
  electricalDesign: 'Electrical Design',
  electricalComponents: 'Electrical/ I&C Components',
  electricalSystems: 'Electrical/ I&C Systems',
  erPrograms: 'ER Programs',
  fitnessForServiceAssessment: 'Fitness for Service Assessment',
  fuelHandling: 'Fuel Handling',
  fuelHandlingAndToolingSystems: 'Fuel Handling and Tooling Systems',
  fuelHandlingDesign: 'Fuel Handling Design',
  fuelHandlingEngineering: 'Fuel Handling Engineering',
  fuelManagementAndReactorPhysics: 'Fuel Management and Reactor Physics',
  governanceAndOversight: 'Governance and Oversight',
  electricalDesignProjects: 'Instrumentation, Computer and Electrical Design Projects',
  lifeEngineering: 'Life-X Engineering',
  maintenanceContracts: 'Maintenance Contracts',
  majorComponentsEngineeringPrograms: 'Major Components Engineering Programs',
  mechanicalAndCivil: 'Mechanical and Civil',
  mechanicalComponents: 'Mechanical Components',
  mechanicalDesign: 'Mechanical Design',
  nuclearSafety: 'Nuclear Safety Analysis & Support',
  nuclearSystems: 'Nuclear Systems (NSSS)',
  outagesEngineering: 'Outages Engineering',
  pressureBoundaryPiping: 'Pressure Boundary Piping Engineering Programs',
  probabilisticSafetySupport: 'Probabilistic Safety Support',
  reactorDesign: 'Reactor Design',
  reactorFueling: 'Reactor Fueling',
  reactorInspectionTooling: 'Reactor Inspection Tooling',
  reactorSafetyPlantSupport: 'Reactor Safety Plant Support',
  resourcing: 'Resourcing',
  siteSupportEngineering: 'Site Support Engineering',
  switchyard: 'Switchyard',
  turbineServices: 'Turbine Services',
  reactorSafetyRisk: 'Reactor Safety Risk & Integration',
  researchAndDevelopment: 'R&D and Technical Development',
};

export const RISK_CATEGORIES: Record<keyof RiskCategories, string> = {
  mcrRelatedRisk: 'MCR Related Risk',
  environmentalSafety: 'Environmental Safety',
  industrialSafety: 'Industrial Safety',
  productionCommercialFinancial: 'Production / Commercial / Financial',
  radiologicalSafety: 'Radiological Safety',
  reactorSafety: 'Reactor Safety',
  regulatoryCompliance: 'Regulatory Compliance',
  reputationRisk: 'Reputation Risk',
  equipmentReliabilityGeneration: 'Equipment Reliability',
};

export const RISK_CATEGORIES_DEFAULT: RiskCategories = {
  mcrRelatedRisk: false,
  environmentalSafety: false,
  industrialSafety: false,
  productionCommercialFinancial: false,
  radiologicalSafety: false,
  reactorSafety: false,
  regulatoryCompliance: false,
  reputationRisk: false,
  equipmentReliabilityGeneration: false,
};

export const INTERESTED_PARTIES: Record<keyof InterestedParties, string> = {
  cnsc: 'CNSC',
  environmentRegulators: 'Environment Regulators',
  governmentOther: 'Government - Other',
  hydroOne: 'Hydro One',
  ieso: 'IESO',
  indigenousGroup: 'Indigenous Group',
  industryOther: 'Industry - Other',
  media: 'Media',
  opg: 'OPG',
  other: 'Other',
  partners: 'Partners',
  publicParty: 'Public',
};

export const INTERESTED_PARTIES_DEFAULT: InterestedParties = {
  cnsc: false,
  environmentRegulators: false,
  governmentOther: false,
  hydroOne: false,
  ieso: false,
  indigenousGroup: false,
  industryOther: false,
  media: false,
  opg: false,
  other: false,
  partners: false,
  publicParty: false,
};

export const RADIO_OPTIONS = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
  { label: 'Not Applicable', value: 'notApplicable' },
];
export const INITIAL_RISK_OWNER_FORM_VALUES: RiskOwnerFormInputs = {
  riskTitle: '',
  elementId: 0,
  description: '',
  opportunityOrThreat: '',
  engineeringEquipmentPerformance: '',
  endOfLife: 'no',
  bridgingStrategy: 'no',
  bridgingStrategyDescription: '',
  spv: 'no',
  spvEliminationOrMitigation: '',
  spvDescription: '',
  criticalStrategicSpares: 'no',
  criticalDescription: '',
  obsolescenceIssue: 'no',
  obsolescenceDescription: '',
  changesSinceLastQ: '',
  currentStatusRating: '',
  currentStatusComment: '',
  contingencyPlanDescription: '',
  contingencyPlanningDate: '',
  contingencyPlanningStatus: '',
  businessObjectives: '',
  msmProgram: [],
  plannedOutageReference: [],
  climateChange: 'no',
  climateDescription: '',
  facility: '',
  overlifePotentialCommercialImpact: 0,
  overlifeImpactDescription: '',
  units: [],
  riskCategories: RISK_CATEGORIES_DEFAULT,
  riskImpactMcr: '',
  reputationDescription: '',
  responseType: '',
  businessPlanDescription: '',
  estimationDescription: '',
  otherInfoDescription: '',
  interestedParties: INTERESTED_PARTIES_DEFAULT,
};

export type RiskOwnerForm = {
  riskStatus: string;
  control: Control<RiskOwnerFormInputs>;
  handleSubmit: () => Promise<void>;
  setSubmitting: (submitting: boolean) => void;
  submitting: boolean;
  saveDraft: () => Promise<void>;
  errors: FieldErrors<RiskOwnerFormInputs>;
  loading: boolean;
  watchSpv: string;
  watchElementId: number;
  watchOpportunityOrThreat: string;
  radioOptions: RadioOption[];
  riskCategories: Record<keyof RiskCategories, string>;
  interestedParties: Record<keyof InterestedParties, string>;
  watchContingencyPlanningStatus: string;
  watchClimateChange: string;
  watchFacility: RiskOwnerFormInputs['facility'];
  watchRiskCategory: RiskCategories;
  watchPotentialOverlife: string;
  watchBridgingStrategy: string;
  watchCritical: string;
  watchObsolescence: string;
  watchEnv: boolean;
  watchHealthScore: number | undefined;
  watchHealthScoreColor: string | undefined;
  unitsOptions: AutocompleteOption<Unit>[];
  isReadOnly: boolean;
  rsaArcherRskRecordReference: string | null;
  riskOwner: string;
  onNotifyRiskApproversSubmit: (envRequired: boolean) => (users: string[]) => void;
  onCloseNotifyRiskApprovers: () => void;
  notifyRiskApprovers: boolean;
  setValue: UseFormSetValue<RiskOwnerFormInputs>;
  engineeringPerformanceOptions: RadioOption[];
};

export const getOutagePlansOptions = (outagePlans: OutagePlan[]) =>
  outagePlans
    .filter((plan: OutagePlan) => plan.status === 'Planned' && !plan.is_deleted)
    .map((plan: OutagePlan) => ({ label: plan.outage_code, value: plan.outage_code }));

export const getBusinessObjectivesOptions = (businessObjectives: BusinessObjective[]) =>
  businessObjectives
    .filter(obj => obj.status === 'Active' && !obj.is_deleted)
    .map((obj: BusinessObjective) => ({ label: obj.title, value: obj.title }));

export const getMSMPrograms = (msmPrograms: MSMProgram[]) =>
  msmPrograms
    .filter(obj => obj.status === 'Active' && !obj.is_deleted)
    .map((obj: MSMProgram) => ({ label: obj.msm_program, value: obj.msm_program }));

export const getUnitsOptions = (facility: RiskOwnerFormInputs['facility']): RiskOwnerForm['unitsOptions'] => {
  const applicableUnits = getUnitsForFacility(facility || 'All');
  return applicableUnits.map(unit => ({ value: unit, label: unit }));
};

export const elementsOptions = (elements: MyElement[]) =>
  elements.map(element => ({
    label: `[${elementTypePrefix(element.elementType)}] ${element.elementName} (${element.facilityName})`,
    value: element.elementId,
  }));

export const useRiskOwnerForm = (riskId: number): RiskOwnerForm => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { users } = useUsers();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [riskStatus, setRiskStatus] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [rsaArcherRskRecordReference, setRsaArcherRskRecordReference] = useState<string | null>(null);
  const [riskOwner, setRiskOwner] = useState<string>(authProvider.getAccount().name);
  const [notifyRiskApprovers, setNotifyRiskApprovers] = useState(false);

  const initialValues = useMemo<RiskOwnerFormInputs>(() => INITIAL_RISK_OWNER_FORM_VALUES, [riskId]);

  useEffect(() => {
    if (pathname.includes('/edit')) {
      void getRiskOwnerForm();
    }
    if (pathname.includes('/archive')) {
      void getRiskOwnerForm();
    }
    if (pathname.includes('/review')) {
      void getRiskOwnerForm();
    }
    return () => {
      reset();
      setRsaArcherRskRecordReference(null);
      setRiskOwner(authProvider.getAccount().name);
      setRiskStatus('');
    };
  }, [pathname, riskId]);

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
    setValue,
    reset,
    setError,
    resetField,
  } = useForm<RiskOwnerFormInputs>({
    defaultValues: initialValues,
  });

  const getRiskOwnerForm = useCallback(async () => {
    if (!riskId) return null;
    const { risk } = await api.getRiskOwnerForm(riskId);
    const generalRisk = await api.getRiskGeneralForm(riskId);
    setRiskStatus(generalRisk.status);
    Object.keys(risk).forEach((key: string) => {
      setValue(key as keyof RiskOwnerFormInputs, risk[key]);
    });
    setRsaArcherRskRecordReference(risk.rsaArcherRskRecordReference || null);
    const riskOwner = users.find((user: User) => user.id === risk.riskOwner);
    !!riskOwner && setRiskOwner(riskOwner.name);
    return;
  }, [riskId]);

  const onNotifyRiskApproversSubmit = useCallback(
    (envRequired: boolean) => async (users: string[]) => {
      if (!riskId) return;
      setLoading(true);
      await api.notifyRiskApprovers(riskId, users, envRequired);
      setNotifyRiskApprovers(false);
      setLoading(false);
      push('/app/business-equipment-risks');
    },
    [riskId]
  );

  const onCloseNotifyRiskApprovers = useCallback(() => {
    setNotifyRiskApprovers(false);
    push('/app/business-equipment-risks');
  }, []);

  // TODO: Part of temp fix for the render "lag", remove when the issue is fixed
  const onInvalid = () => {
    setSubmitting(false);
  };

  const onSubmit: SubmitHandler<RiskOwnerFormInputs> = useCallback(
    async data => {
      try {
        if (pathname.includes('/edit')) {
          await api.updateRiskOwnerForm(data, riskId);
          dispatch(uiActions.genericMessage('Risk Form Updated'));
        } else {
          await api.submitRiskOwnerForm(data, riskId);
          dispatch(uiActions.genericMessage('Risk Form Submitted'));
        }
        setNotifyRiskApprovers(true);
      } catch (error) {
        console.error('error', error);
        dispatch(uiActions.error('', 'Error: Unable to submit or update form'));
      }
    },
    [riskId]
  );

  const onSaveDraft = useCallback(async () => {
    try {
      const data = getValues();
      if (data.overlifePotentialCommercialImpact < 0) {
        setError('overlifePotentialCommercialImpact', {
          type: 'manual',
          message: 'Enter a non-negative number',
        });
        return;
      }
      if (pathname.includes('/edit')) {
        await api.updateDraftRiskOwnerForm({ ...data, healthScore: undefined, healthScoreColor: undefined }, riskId);
        dispatch(uiActions.genericMessage('Draft Updated'));
      } else {
        await api.saveDraftRiskOwnerForm({ ...data, healthScore: undefined, healthScoreColor: undefined }, riskId);
        dispatch(uiActions.genericMessage('Draft Saved'));
        push(`/app/business-equipment-risks/${riskId}/edit`);
      }
    } catch (error) {
      console.error('error', error);
      dispatch(uiActions.error('', 'Error: Unable to save or update draft'));
    }
  }, [riskId, pathname]);

  const setUnitsOptions: RiskOwnerForm['unitsOptions'] = useMemo(() => {
    const unitOptions = getUnitsOptions(watch('facility'));
    const values = unitOptions.map(option => option.value);
    if (!getValues('units')?.every(v => values.includes(v))) {
      // TODO-RS: No side effects in useMemo 🙅‍♀️
      resetField('units');
    }
    return unitOptions;
  }, [watch('facility')]);

  const watchOpportunityOrThreat = watch('opportunityOrThreat');

  const engineeringPerformanceOptions = useMemo(() => {
    return Object.entries(ENGINEERING_PERFORMANCE_CATEGORIES).map(([key, value]) => {
      return { label: value, value: key };
    });
  }, []);

  return {
    riskStatus,
    control,
    loading,
    handleSubmit: handleSubmit(onSubmit, onInvalid),
    setSubmitting,
    submitting,
    saveDraft: onSaveDraft,
    errors,
    watchSpv: watch('spv'),
    watchElementId: watch('elementId'),
    watchOpportunityOrThreat,
    radioOptions: RADIO_OPTIONS,
    riskCategories: RISK_CATEGORIES,
    interestedParties: INTERESTED_PARTIES,
    watchContingencyPlanningStatus: watch('contingencyPlanningStatus'),
    watchClimateChange: watch('climateChange'),
    watchFacility: watch('facility'),
    watchPotentialOverlife: watch('endOfLife'),
    watchBridgingStrategy: watch('bridgingStrategy'),
    watchCritical: watch('criticalStrategicSpares'),
    watchObsolescence: watch('obsolescenceIssue'),
    watchEnv: watch('riskCategories.environmentalSafety'),
    watchHealthScore: watch('healthScore'),
    watchHealthScoreColor: watch('healthScoreColor'),
    unitsOptions: setUnitsOptions,
    watchRiskCategory: watch('riskCategories'),
    isReadOnly: pathname.includes('/archive') || pathname.includes('/review'),
    rsaArcherRskRecordReference,
    riskOwner,
    onNotifyRiskApproversSubmit,
    onCloseNotifyRiskApprovers,
    notifyRiskApprovers,
    setValue,
    engineeringPerformanceOptions,
  };
};
