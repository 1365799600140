import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';

import { Scorecard } from 'echo-scorecard';
import * as scorecardActions from '../state/scorecard/actions';
import * as scorecardSelectors from '../state/scorecard/selectors';

const unitsFromScorecard = R.compose<any, any, any, any, any, any>(
  R.sort(R.comparator(R.lt)),
  R.uniq,
  R.flatten,
  R.map(R.keys),
  R.values
);

const useScorecard = (elementId: number, year: number, quarter: number) => {
  const dispatch = useDispatch();

  const loadScorecard = useCallback(() => {
    dispatch(scorecardActions.loadScorecardRequest(elementId, year, quarter));
  }, [dispatch, elementId, year, quarter]);

  useEffect(() => {
    loadScorecard();
  }, [loadScorecard]);

  const scorecard: Scorecard = useSelector(state =>
    scorecardSelectors.getScorecard(state, elementId, year, quarter)
  ) as Scorecard;
  const scorecardUnits = unitsFromScorecard(scorecard as any);
  const lastUpdatedAt = useSelector(state => scorecardSelectors.lastUpdatedAt(state, elementId, year, quarter));

  const isLoading = useSelector(state => scorecardSelectors.isLoading(state, elementId, year, quarter));

  return {
    scorecard,
    scorecardUnits,
    isLoading,
    lastUpdatedAt,
    loadScorecard,
  };
};

export default useScorecard;
