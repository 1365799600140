import { Grid } from '@material-ui/core';
import DropdownAutocomplete from '../../../components/DropdownAutocomplete';
import {
  ACCEPT_AND_KEEP_ACTIVE,
  ACCEPT_AND_CLOSE,
  REJECT_AND_CLOSE,
  REJECT_AND_RESUBMIT,
  RISK_STATES,
} from '../constants';
import useRiskManagement from '../../../context/risk-management';
import { useFilterAllElements, useFilterMyElements } from '../../../components/FilterPanel';
import { Dispatch, SetStateAction } from 'react';
import Panel from '../../../components/Panel';
import elementTypePrefix from '../../../utils/element-type-prefix';

interface Props {
  onChangeFilterOptions: (key: string, value: string | null) => void;
  riskIds: number[];
  riskTitles: string[];
  workflowStatusDates: string[];
  elementId?: string;
  setElementId: Dispatch<SetStateAction<string>>;
  years: number[];
  riskOwners: string[];
  envTeamContacts: string[];
}

export const FilterOptions = ({
  onChangeFilterOptions,
  riskIds,
  riskTitles,
  workflowStatusDates,
  elementId,
  setElementId,
  years,
  riskOwners,
  envTeamContacts,
}: Props) => {
  const { currentRiskManagementUserRoles, myElements } = useRiskManagement();
  const { elementOptions: rseElementOptions } = useFilterMyElements();
  const { elementOptions: allElementOptions } = useFilterAllElements();

  const riskOwnerElementOptions = myElements.map(
    (element: { elementType: string; elementName: string; facilityName: string; elementId: string }) => ({
      label: `[${elementTypePrefix(element.elementType)}] ${element.elementName} (${element.facilityName})`,
      value: element.elementId,
    })
  );

  const elementOptions = [...riskOwnerElementOptions, ...rseElementOptions].filter(
    (option, index, self) => index === self.findIndex(t => t.value === option.value)
  );

  return (
    <Panel>
      <Grid
        container
        xs={12}
        spacing={1}
      >
        <Grid
          item
          lg={2}
          md={4}
          sm={6}
          xs={12}
        >
          <DropdownAutocomplete
            defaultValue={null}
            overrideDefault
            label="Element"
            value={elementId || ''}
            options={currentRiskManagementUserRoles.length > 0 ? allElementOptions : elementOptions}
            onChange={(e: any) => setElementId(e || '')}
          />
        </Grid>

        <Grid
          item
          lg={2}
          md={4}
          sm={6}
          xs={12}
        >
          <DropdownAutocomplete
            label="Risk Id"
            options={riskIds.map((id: number) => ({
              label: id.toString(),
              value: id,
            }))}
            onChange={val => onChangeFilterOptions('riskId', val)}
          />
        </Grid>

        <Grid
          item
          lg={2}
          md={4}
          sm={6}
          xs={12}
        >
          <DropdownAutocomplete
            label="Risk Titles"
            options={riskTitles.map((title: string) => ({
              label: title,
              value: title,
            }))}
            onChange={val => onChangeFilterOptions('riskTitle', val)}
          />
        </Grid>

        <Grid
          item
          lg={2}
          md={4}
          sm={6}
          xs={12}
        >
          <DropdownAutocomplete
            defaultValue={null}
            overrideDefault
            label="Decision Type"
            options={[
              {
                label: ACCEPT_AND_KEEP_ACTIVE,
                value: 'acceptAndMonitor',
              },
              {
                label: ACCEPT_AND_CLOSE,
                value: 'acceptAndClose',
              },
              {
                label: REJECT_AND_CLOSE,
                value: 'rejectAndClose',
              },
              {
                label: REJECT_AND_RESUBMIT,
                value: 'rejectAndResubmit',
              },
            ]}
            onChange={val => onChangeFilterOptions('decisionType', val)}
          />
        </Grid>
        <Grid
          item
          lg={2}
          md={4}
          sm={6}
          xs={12}
        >
          <DropdownAutocomplete
            defaultValue={null}
            overrideDefault
            label="Quarter"
            options={[
              {
                label: 'Q1',
                value: '1',
              },
              {
                label: 'Q2',
                value: '2',
              },
              {
                label: 'Q3',
                value: '3',
              },
              {
                label: 'Q4',
                value: '4',
              },
            ]}
            onChange={val => onChangeFilterOptions('quarter', val)}
          />
        </Grid>
        <Grid
          item
          lg={2}
          md={4}
          sm={6}
          xs={12}
        >
          <DropdownAutocomplete
            label="Workflow Status Date"
            options={workflowStatusDates.map((date: string) => ({
              label: date,
              value: date,
            }))}
            onChange={val => onChangeFilterOptions('workflowStatusDate', val)}
          />
        </Grid>
        <Grid
          item
          lg={2}
          md={4}
          sm={6}
          xs={12}
        >
          <DropdownAutocomplete
            label="Year"
            options={years.map((year: number) => ({
              label: year,
              value: year,
            }))}
            onChange={val => onChangeFilterOptions('year', val)}
          />
        </Grid>
        <Grid
          item
          lg={2}
          md={4}
          sm={6}
          xs={12}
        >
          <DropdownAutocomplete
            label="Risk Owner"
            options={riskOwners.map((riskOwner: string) => ({
              label: riskOwner,
              value: riskOwner,
            }))}
            onChange={val => onChangeFilterOptions('riskOwner', val)}
          />
        </Grid>
        <Grid
          item
          lg={2}
          md={4}
          sm={6}
          xs={12}
        >
          <DropdownAutocomplete
            label="Environmental Group Member"
            options={envTeamContacts.map((envTeamContact: string) => ({
              label: envTeamContact,
              value: envTeamContact,
            }))}
            onChange={val => onChangeFilterOptions('envTeamContact', val)}
          />
        </Grid>
        <Grid
          item
          lg={2}
          md={4}
          sm={6}
          xs={12}
        >
          <DropdownAutocomplete
            label="Workflow Status"
            defaultValue={null}
            overrideDefault
            options={[
              {
                label: RISK_STATES.SUBMITTED,
                value: RISK_STATES.SUBMITTED,
              },
              {
                label: RISK_STATES.ENV_REQUIRED,
                value: RISK_STATES.ENV_REQUIRED,
              },
              {
                label: RISK_STATES.DRAFT,
                value: RISK_STATES.DRAFT,
              },
              {
                label: RISK_STATES.CLOSED,
                value: RISK_STATES.CLOSED,
              },
              {
                label: RISK_STATES.APPROVED,
                value: RISK_STATES.APPROVED,
              },
            ]}
            onChange={val => onChangeFilterOptions('workflowStatus', val)}
          />
        </Grid>
      </Grid>
    </Panel>
  );
};
