import * as R from 'ramda';
import scorecardKey from './keys';

// eslint-disable-next-line import/prefer-default-export
export const getScorecard = (state, elementId, year, quarter) =>
  R.pathOr({}, ['scorecard', scorecardKey(elementId, year, quarter), 'scorecard'], state);

export const isLoading = (state, elementId, year, quarter) =>
  R.pathOr(false, ['scorecard', scorecardKey(elementId, year, quarter), 'isLoading'], state);

export const lastUpdatedAt = (state, elementId, year, quarter) =>
  R.path(['scorecard', scorecardKey(elementId, year, quarter), 'lastUpdatedAt'], state);

export const getError = (state, elementId, year, quarter) =>
  R.path(['scorecard', scorecardKey(elementId, year, quarter), 'error'], state);
