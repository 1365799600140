import { call, put, takeEvery } from 'redux-saga/effects';

import Api from '../../api';
import * as actionTypes from '../action-types';
import * as actions from './actions';

function* loadRecordsSummary(action) {
  const { indicatorId, elementId } = action.payload;
  try {
    const recordsSummary = yield call(Api.getReportIndicatorRecords, indicatorId, elementId);
    yield put(actions.loadRecordsSummarySuccess(indicatorId, recordsSummary));
  } catch (e) {
    yield put(actions.loadRecordsSummaryFailure(e, indicatorId));
  }
}

function* root() {
  yield takeEvery(actionTypes.RECORDS_SUMMARY_LOAD_REQUEST, loadRecordsSummary);
}

export default root;
