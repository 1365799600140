import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import ReportGenContainer from './ReportGenContainer';
import ReviewRecordsPage from './review-records/ReviewRecordsPage';
import ReviewShipPage from './review-ship/ReviewShipPage';
import ExecutiveSummaryPage from './executive-summary/ExecutiveSummaryPage';
import ScorecardPage from './scorecard/ScorecardPage';
import UploadAttachmentsPage from './upload-attachments/UploadAttachmentsPage';
import ReviewAndSubmitPage from './review-and-submit/ReviewAndSubmitPage';
import useReport from '../../../hooks/report';
import { Downloader } from '../../../hooks/downloader';
import useComments from './executive-summary/hooks/comments';

const reportPages = (elementType: string) => {
  const reviewPageTitle: any = {
    program: 'Review PHIP',
    system: 'Review SHIP',
    component: 'Review CHIP',
  };

  const pages = [
    {
      pageId: 'review-records',
      title: 'Review Records',
      component: ReviewRecordsPage,
    },
    {
      pageId: 'scorecard',
      title: 'Scorecard',
      component: ScorecardPage,
    },
    {
      pageId: 'review-ship',
      title: reviewPageTitle[elementType],
      component: ReviewShipPage,
    },
    {
      pageId: 'executive-summary',
      title: 'Executive Summary',
      component: ExecutiveSummaryPage,
    },
    {
      pageId: 'upload-attachments',
      title: 'Upload Attachments',
      component: UploadAttachmentsPage,
    },
    {
      pageId: 'review-and-submit',
      title: 'Review And Submit',
      component: ReviewAndSubmitPage,
    },
  ];
  if (elementType === 'program') pages.shift();
  return pages;
};

const ReportGenRouter = () => {
  const { path, url } = useRouteMatch();
  const { reportId } = useParams<any>();
  const history = useHistory();
  const { report } = useReport(reportId);
  const { comments } = useComments(reportId);
  const [pages, setPages] = useState(reportPages('system'));

  useEffect(() => {
    if (report) {
      setPages(reportPages(report.elementType));
    }
  }, [report]);

  const handleNext = (step: number) => () => {
    history.push(pages[step + 1].pageId);
  };

  const handleBack = (step: number) => () => {
    history.push(pages[step - 1].pageId);
  };

  const handleJumpTo = (newPageId: string) => {
    // are we good to navigate away? if so...
    history.push(newPageId);
  };

  const routes = pages.map(({ pageId, title, component: PageComponent }, i) => (
    <Route
      key={pageId}
      exact
      path={`${path}/${pageId}`}
    >
      <ReportGenContainer
        report={report}
        comment={comments}
        pageTitle={title}
        currentStep={i}
        pages={pages}
        onJumpTo={handleJumpTo}
      >
        <PageComponent
          report={report}
          comment={comments}
          onNext={handleNext(i)}
          onBack={handleBack(i)}
        />
      </ReportGenContainer>
    </Route>
  ));

  return (
    <Switch>
      {routes}
      <Route>
        <Redirect to={report && report.elementType === 'program' ? `${url}/scorecard` : `${url}/review-records`} />
      </Route>
    </Switch>
  );
};

const RGRouter = () => (
  <Downloader>
    <ReportGenRouter />
  </Downloader>
);
export default RGRouter;
