import React from 'react';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import { Control, FieldErrors } from 'react-hook-form';

import { UppercaseTitle } from './UppercaseTitle';
import { ControlSelect } from '../../../../form/ControlSelect';
import { ControlRadio } from '../../../../form/ControlRadio';
import { ControlTextField } from '../../../../form/ControlTextField';
import { WrappedInput } from './WrappedInput';
import { RiskOwnerFormInputs } from '../../../../types/risk-management';
import { RadioOption } from '../../../../components/CustomRadioGroup';

export const StyledListItem = styled.li`
  display: list-item;
  padding-bottom: 0;
`;

type Props = {
  control: Control<RiskOwnerFormInputs>;
  errors: FieldErrors<RiskOwnerFormInputs>;
  radioOptions: RadioOption[];
  watchSpv: string;
  isReadOnly: boolean;
  watchPotentialOverlife: string;
  watchBridgingStrategy: string;
  watchCritical: string;
  watchObsolescence: string;
  engineeringPerformanceOptions: RadioOption[];
};

export const EquipmentPerformanceInformation: React.FC<Props> = ({
  control,
  errors,
  radioOptions,
  watchSpv,
  isReadOnly,
  watchPotentialOverlife,
  watchBridgingStrategy,
  watchCritical,
  watchObsolescence,
  engineeringPerformanceOptions,
}) => {
  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={12}
      >
        <UppercaseTitle>Other Equipment Performance Information</UppercaseTitle>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <WrappedInput>
          <ControlSelect
            name="engineeringEquipmentPerformance"
            control={control}
            required={true}
            label="Engineering/Equipment Performance Section"
            options={engineeringPerformanceOptions}
            error={!!errors.engineeringEquipmentPerformance}
            disabled={isReadOnly}
          />
        </WrappedInput>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <ControlRadio
          name="endOfLife"
          control={control}
          required={true}
          error={!!errors.endOfLife}
          label="Is there a potential overlife impact to the business (present to 2064)?"
          options={radioOptions}
          disabled={isReadOnly}
          row
        />
      </Grid>
      {watchPotentialOverlife === 'yes' && (
        <Grid
          item
          xs={12}
        >
          <WrappedInput>
            <ControlTextField
              name="overlifePotentialCommercialImpact"
              control={control}
              type="number"
              required={true}
              error={!!errors.overlifePotentialCommercialImpact}
              validate={value => value >= 0}
              label="Overlife Potential Commercial Impact ($M)"
              helperText="Enter a non-negative number"
              disabled={isReadOnly}
            />
          </WrappedInput>
        </Grid>
      )}
      {watchPotentialOverlife === 'yes' && (
        <Grid
          item
          xs={12}
        >
          <WrappedInput>
            <ControlTextField
              name="overlifeImpactDescription"
              control={control}
              required={true}
              error={!!errors.overlifeImpactDescription}
              label="Overlife Potential Impact Description"
              disabled={isReadOnly}
              multiline
              minRows={3}
              maxLength={1000}
              placeholder="Required (Character Limitation: 1000 characters)"
            />
          </WrappedInput>
        </Grid>
      )}

      <Grid
        item
        xs={12}
      >
        <ControlRadio
          name="bridgingStrategy"
          control={control}
          required={true}
          error={!!errors.bridgingStrategy}
          label="Bridging Strategy needed?"
          options={radioOptions}
          disabled={isReadOnly}
          row
        />
      </Grid>
      {watchBridgingStrategy === 'yes' && (
        <Grid
          item
          xs={12}
        >
          <WrappedInput>
            <ControlTextField
              name="bridgingStrategyDescription"
              control={control}
              required={true}
              error={!!errors.bridgingStrategyDescription}
              label="Description of Bridging Strategy"
              disabled={isReadOnly}
              multiline
              minRows={3}
              maxLength={1000}
              placeholder="Required (Character Limitation: 1000 characters)"
            />
          </WrappedInput>
        </Grid>
      )}

      <Grid
        item
        xs={12}
      >
        <ControlRadio
          name="spv"
          control={control}
          required={true}
          error={!!errors.spv}
          label="Is this an SPV?"
          options={radioOptions}
          disabled={isReadOnly}
          row
        />
      </Grid>
      {watchSpv === 'yes' && (
        <Grid
          item
          xs={12}
        >
          <WrappedInput>
            <ControlSelect
              name="spvEliminationOrMitigation"
              control={control}
              required={true}
              label="SPV Elimination or Mitigation?"
              options={[
                { label: 'SPV Elimination', value: 'elimination' },
                { label: 'SPV Mitigation', value: 'mitigation' },
              ]}
              error={!!errors.spvEliminationOrMitigation}
              disabled={isReadOnly}
            />
          </WrappedInput>
        </Grid>
      )}
      {watchSpv === 'yes' && (
        <Grid
          item
          xs={12}
        >
          <WrappedInput>
            <ControlTextField
              name="spvDescription"
              control={control}
              required={true}
              error={!!errors.spvDescription}
              label="Description of SPV"
              disabled={isReadOnly}
              multiline
              minRows={3}
              maxLength={1000}
              placeholder="Required (Character Limitation: 1000 characters)"
            />
          </WrappedInput>
        </Grid>
      )}
      <Grid
        item
        xs={12}
      >
        <ControlRadio
          name="criticalStrategicSpares"
          control={control}
          required={true}
          error={!!errors.criticalStrategicSpares}
          label="Critical or Strategic Spares required?"
          options={radioOptions}
          disabled={isReadOnly}
          row
        />
      </Grid>
      {watchCritical === 'yes' && (
        <Grid
          item
          xs={12}
        >
          <WrappedInput>
            <ControlTextField
              name="criticalDescription"
              control={control}
              required={true}
              error={!!errors.criticalDescription}
              label="Description of Critical or Strategic Spares"
              disabled={isReadOnly}
              multiline
              minRows={3}
              maxLength={1000}
              placeholder="Required (Character Limitation: 1000 characters)"
            />
          </WrappedInput>
        </Grid>
      )}
      <Grid
        item
        xs={12}
      >
        <ControlRadio
          name="obsolescenceIssue"
          control={control}
          required={true}
          error={!!errors.obsolescenceIssue}
          label="Is this an Obsolescence Issue?"
          options={radioOptions}
          disabled={isReadOnly}
          row
        />
      </Grid>
      {watchObsolescence === 'yes' && (
        <Grid
          item
          xs={12}
        >
          <WrappedInput>
            <ControlTextField
              name="obsolescenceDescription"
              control={control}
              required={true}
              error={!!errors.obsolescenceDescription}
              label="Description of Obsolescence Issue "
              disabled={isReadOnly}
              multiline
              minRows={3}
              maxLength={1000}
              placeholder="Required (Character Limitation: 1000 characters)"
            />
          </WrappedInput>
        </Grid>
      )}
    </Grid>
  );
};
