import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as indicatorRecordActions from '../../../../../state/indicator-records/actions';
import { propFn } from '../../../../../utils/indicator-specifics';

// NOTE: This hook is deprecated. Please do not use it elsewhere.
// It is only used in the record details page.
const useDetailsIndicatorRecordApplicability = (
  indicatorId: string,
  indicatorShortName: string,
  elementId: string,
  selectedRecord: any
) => {
  const dispatch = useDispatch();

  const indicatorRecordId: any = propFn(indicatorShortName);

  const recordId = indicatorRecordId(selectedRecord);

  const setInapplicable = useCallback(
    (options: any) => {
      dispatch(
        indicatorRecordActions.updateApplicabilityRequest(
          elementId,
          undefined,
          undefined,
          indicatorId,
          indicatorShortName,
          recordId,
          false,
          options
        )
      );
    },
    [dispatch, indicatorId, indicatorShortName, elementId, recordId]
  );

  const setApplicable = useCallback(
    (options: any) => {
      dispatch(
        indicatorRecordActions.updateApplicabilityRequest(
          elementId,
          undefined,
          undefined,
          indicatorId,
          indicatorShortName,
          recordId,
          true,
          options
        )
      );
    },
    [dispatch, indicatorId, indicatorShortName, elementId, recordId]
  );

  const setSelectedRecordApplicable = useCallback(
    (options: any) => {
      setApplicable(options);
    },
    [setApplicable]
  );

  const setSelectedRecordInapplicable = useCallback(
    (options?: any) => {
      setInapplicable(options);
    },
    [setInapplicable]
  );

  return {
    setSelectedRecordApplicable,
    setSelectedRecordInapplicable,
  };
};

export default useDetailsIndicatorRecordApplicability;
