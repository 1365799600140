import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import useQuarters from '../../../../hooks/quarters';
import useScorecard from '../../../../hooks/scorecard';
import useForecast from '../../../../hooks/forecast';
import ForecastChart from '../../../../components/ForecastChart';
import ForecastDetails from './ForecastDetails';
import useActions from '../../../../hooks/actions';
import { ElementIndicatorsProvider } from '../../../../hooks/element-indicators';
import useElementActuals, { ActualsProvider } from '../../../../hooks/useElementActuals';
import { Scorecard } from '../../../../types/scorecard';

const WidthConstrainedGrid = styled(Grid)`
  max-width: 1200px;
`;

interface ContainerProps {
  actions: any[];
  scorecard: Scorecard;
}

const ForecastContainer = ({ actions, scorecard }: ContainerProps) => {
  const historicData = useElementActuals();
  const forecastEvents = useForecast(actions, scorecard);
  const forecastData = R.map(
    ({ date, scorecard: forecastScorecard, actions: forecastActions }) => ({
      date,
      score: forecastScorecard.totals.score,
      actions: forecastActions,
    }),
    forecastEvents
  );

  return (
    <WidthConstrainedGrid
      container
      direction="column"
      spacing={2}
      style={{ maxWidth: 1200 }}
    >
      <Grid item>
        <ForecastChart
          historicData={historicData}
          forecastData={forecastData}
        />
      </Grid>
      <Grid item>
        <Typography variant="h5">Forecast Details</Typography>
      </Grid>
      <Grid item>
        <ForecastDetails forecast={forecastEvents} />
      </Grid>
    </WidthConstrainedGrid>
  );
};

interface ChartProps {
  elementId: any;
}

const ChartContainer = ({ elementId }: ChartProps) => {
  const { quarters } = useQuarters({ n: 1 });
  const { scorecard } = useScorecard(elementId, quarters[0].year, quarters[0].quarter);
  const { actions } = useActions(elementId, 'ongoing');

  return R.isEmpty(scorecard) || R.isNil(actions) ? (
    <Grid
      container
      justifyContent="center"
    >
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  ) : (
    <ElementIndicatorsProvider elementId={elementId}>
      <ActualsProvider
        elementId={elementId}
        scorecard={scorecard}
      >
        <ForecastContainer
          actions={actions}
          scorecard={scorecard}
        />
      </ActualsProvider>
    </ElementIndicatorsProvider>
  );
};

export default ChartContainer;
